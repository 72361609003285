import { Card, IconButton, List, ListItem, Typography } from '@mui/material';
import UploadMultiFile from '../../../../../components/upload/UploadMultiFile';
import { AnimatePresence, m } from 'framer-motion';
import Image from '../../../../../components/Image';
import { alpha } from '@mui/material/styles';
import React from 'react';

const CashFlowImageUploader = ({ files, setFiles, handleImageUpload, documentGallery, openImageViewer, isUploading }) => {

  // Function to check if a file is an image (JPG or PNG)
  const isImageFile = (file) => {
    const validTypes = ['image/jpeg', 'image/png'];
    return validTypes.includes(file.type);
  };

  return (
    <Card sx={{ p: 3 }}>
      <Typography variant='subtitle1' padding='10px 0'>
        Related Documents
      </Typography>
      <UploadMultiFile
        error={false}
        showPreview={true}
        files={files}
        onRemove={(removedFile) => {
          setFiles(files.filter((file) => file !== removedFile));
        }}
        onRemoveAll={() => {
          setFiles([]);
        }}
        helperText={''}
        onDrop={(uploadedFiles) => {
          // Filter out non-image files
          const validFiles = uploadedFiles.filter(isImageFile);

          setFiles((value) => [
            ...value,
            ...validFiles.map((file, index) =>
              Object.assign(file, {
                id: index,
                preview: URL.createObjectURL(file),
                isUploading: false,
              })
            ),
          ]);
        }}
        onUpload={() => {
          handleImageUpload();
        }}
        isUploading={isUploading}
      />

      {documentGallery?.length > 0 && (
        <Typography variant='subtitle1' padding='10px 0'>
          Uploaded Images
        </Typography>
      )}
      <List disablePadding sx={{ my: 3 }}>
        <AnimatePresence>
          {documentGallery
            ?.filter((value) => value)
            .map((src, index) => (
              <ListItem
                key={index}
                component={m.div}
                onClick={() => openImageViewer(index)}
                sx={{
                  p: 0,
                  m: 0.5,
                  width: 80,
                  height: 80,
                  borderRadius: 1.25,
                  overflow: 'hidden',
                  position: 'relative',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Image alt='preview' src={src} ratio='1/1' />
                <IconButton
                  size='small'
                  sx={{
                    top: 6,
                    p: '2px',
                    right: 6,
                    position: 'absolute',
                    color: 'common.white',
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                    },
                  }}
                />
              </ListItem>
            ))}
        </AnimatePresence>
      </List>
    </Card>
  );
};

export default CashFlowImageUploader;
