import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import Page from '../../../../components/Page';
import useSettings from '../../../../hooks/useSettings';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { fCurrency } from '../../../../utils/formatNumber';
import { dispatch, useSelector } from '../../../../redux/store';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import { Autocomplete, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { getCashFlowLedger, getCashFlowSubCategories, verifyDrawerCloseAmounts } from '../../../../redux/slices/financeRedux/cashFlowRedux';
import utcMoment from 'moment';

export default function SaleDepositVerification() {
  const { themeStretch } = useSettings();
  let locationList;
  let selectedLocation;
  ({ data: locationList, selectedLocation } = useSelector((state) => state.location));
  const { loggedInUser } = useSelector((state) => state.user);
  const dataModel = {
    selectedLocationId: selectedLocation?.id,
    toData: [],
    amount: null,
  };
  const [selectedData, setSelectedData] = useState(dataModel);

  const { data, addData, cashFlowSubCategories } = useSelector((state) => state.cashFlow);

  const [fromLedgerList, setFromLedgerList] = useState([]);
  const [toLedgerList, setToLedgerList] = useState([]);
  const [verifiableOrderList, setVerifiableOrderList] = useState([]);
  const updateDataOnObj = (key, value) => {
    setSelectedData({
      ...selectedData,
      [key]: value,
    });
  };


  useEffect(() => {
    if(selectedLocation?.id){
      setSelectedData(prevState => {
        return {
          ...prevState,
          selectedLocationId: selectedLocation?.id
        }
      })
    }
  }, [selectedLocation]);

  useEffect(() => {
    setFromLedgerList(cashFlowSubCategories?.filter(value => [67, 68, 69, 106, 107, 108, 109].includes(value?.id)));
  }, [cashFlowSubCategories]);

  useEffect(() => {
    setVerifiableOrderList(data?.filter(value => Number(value?.isDeposited) === 0));
  }, [data]);

  useEffect(() => {
    let allowedIdList = [];
    const ledgerIdMap = {
      67: [110, 111, 112, 113, 121], // Cash Sale Receivable
      68: [110, 111, 112, 119],      // Other Card Sale Receivable
      69: [110, 111, 112, 118],      // Amex Card Sale Receivable
      106: [110, 111, 112, 117],     // Pick Me Sale Receivable
      107: [110, 111, 112, 116],     // Uber Sale Receivable
      108: [110, 111, 112],     // Voucher Sale Receivable
      109: [110, 111, 112],     // Discount Sale Receivable
    };
    if (ledgerIdMap[selectedData?.fromLedgerId]) {
      allowedIdList = ledgerIdMap[selectedData?.fromLedgerId];
      setSelectedData(prevState => {
        return {
          ...prevState,
          toData: allowedIdList?.map(value => {
            return {
              id: value,
              name: cashFlowSubCategories?.find(sub => value === sub?.id)?.name,
              locationId: null,
              amount: null,
            };
          }),
        };
      });
    }
    setToLedgerList(cashFlowSubCategories?.filter(value => allowedIdList.includes(value?.id)));
  }, [selectedData?.fromLedgerId, cashFlowSubCategories]);

  useEffect(() => {
    dispatch(getCashFlowLedger(selectedLocation?.id, 7));
  }, [selectedLocation]);

  useEffect(() => {
    dispatch(getCashFlowSubCategories());
  }, []);

  useEffect(() => {
    if (addData?.time) {
      dispatch(getCashFlowLedger(selectedLocation?.id, 7));
      setSelectedData(dataModel)
    }
  }, [addData]);


  const handleSubmit = () => {
    if (selectedData.title?.length === 0) {
      toast.error('Title is required!');
    } else if (selectedData.description?.length === 0) {
      toast.error('Description is required!');
    } else if (selectedData.amount === 0) {
      toast.error('Amount is required!');
    } else {
      const invalidLocation = selectedData.toData?.some(item => item?.amount && item?.locationId === null);
      if (invalidLocation) {
        toast.error('Please select a location for the entries with an amount.');
      } else {
        dispatch(verifyDrawerCloseAmounts(selectedData));
      }
    }
  };


  const isPossibleToVerify = () => {
    return selectedData?.amount === selectedData?.toData?.map(item=> item?.amount || 0).reduce((sum, amount) => Number(sum) + Number(amount), 0);  }

  return (
    <Page title={`Sales Deposit Verification`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Sales Deposit Verification`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Finance',
              href: '',
            },
            { name: `Sales Deposit Verification` },
          ]}
          action={
            <>
              <LoadingButton type="submit" variant="contained" size="large" style={{ width: '100%', marginLeft: '8px' }} onClick={handleSubmit} disabled={!isPossibleToVerify()}>
                Verify & Record
              </LoadingButton>
            </>
          }
        />
        <Grid container spacing={1} padding={2}>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ p: 3 }}>
              <Typography variant="subtitle1" padding="10px 0">
                From Payment Information
              </Typography>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3}>
                    <Autocomplete
                      onChange={(event, newValue) => {
                        updateDataOnObj('fromLedgerId', newValue?.value);
                      }}
                      options={fromLedgerList?.map((value) => ({
                        label: `${value?.name}`,
                        value: value?.id,
                      }))}
                      value={
                        fromLedgerList?.find((value) => value.id === selectedData?.fromLedgerId)?.name && {
                          label: `${fromLedgerList?.find((value) => value.id === selectedData?.fromLedgerId)?.name || 'Transactional mediums'}`,
                          value: selectedData?.fromLedgerId,
                        }
                      }
                      renderInput={(params) => <TextField label="Select Ledger" {...params} />}
                    />

                    {selectedData?.fromLedgerId && <Autocomplete
                      onChange={(event, newValue) => {
                        setSelectedData({
                          ...selectedData,
                          selectedSupplierOrderId: newValue?.value,
                          amount: verifiableOrderList?.find(value => value?.id === newValue?.value)?.amount || 0,
                          title: `${loggedInUser?.name} has verified LedgerID: ${newValue?.value}`,
                          description: `${newValue?.value} Ledger record has been verified by ${loggedInUser?.name}`,
                        });
                      }}
                      options={verifiableOrderList?.filter(value => value?.debitLedgerId === selectedData?.fromLedgerId)?.map((value) => ({
                        label: `(${fCurrency(value?.amount)}) - (${utcMoment(value?.datetime).format('DD MMMM YYYY')}) (${value?.debitLocationName}) (ID: ${value?.id}) ${value?.description}`,
                        value: value?.id,
                      }))}
                      value={selectedData?.selectedSupplierOrderId}
                      renderInput={(params) => <TextField label="Select Current Asset Type" {...params} />}
                    />}
                    {selectedData?.title&&<>
                    <hr />
                    <h3><span
                      style={{ fontSize: '14px', color: 'darkgray' }}>Description: </span>{selectedData?.title || null}
                    </h3>
                    <h3><span style={{
                      fontSize: '14px',
                      color: 'darkgray',
                    }}>Amount: </span>{fCurrency(selectedData?.amount || 0)}</h3>
                    </>}
                  </Stack>
                </Card>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <Card sx={{ p: 3 }}>
              <Typography variant="subtitle1" padding="10px 0">
                To Payment Information
              </Typography>
              <Stack spacing={3} sx={{ p: 1 }}>
                {selectedData?.toData?.map((data, index) => (
                  <div key={index}>
                    <h5>{data?.name}</h5>
                    <Grid container spacing={1} padding={0}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Autocomplete
                          onChange={(event, newValue) => {
                            setSelectedData(prevState => ({
                              ...prevState,
                              toData: prevState?.toData?.map((value, indexTo) => {
                                if (index === indexTo) {
                                  return { ...value, locationId: newValue?.value };
                                }
                                return value;
                              })
                            }));
                          }}
                          options={locationList?.map(value => ({
                            label: value?.name,
                            value: value?.id,
                          }))}
                          value={
                            locationList?.find(value => value.id === data?.locationId) ? {
                              label: locationList?.find(value => value.id === data?.locationId)?.name,
                              value: data?.locationId,
                            } : null
                          }
                          renderInput={params => <TextField label="Location Name" {...params} />}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          fullWidth
                          type="number"
                          label="Amount"
                          value={data?.amount || ''}
                          onChange={e => {
                            setSelectedData(prevState => ({
                              ...prevState,
                              toData: prevState?.toData?.map((value, indexTo) => {
                                if (index === indexTo) {
                                  return { ...value, amount: e.target.value };
                                }
                                return value;
                              })
                            }));
                          }}
                        />
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Stack>

            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
