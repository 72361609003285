import React, { useEffect, useState } from 'react';
import { Container, IconButton, Tooltip } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { fCurrency } from '../../../../utils/formatNumber';
import InvoicePDF from '../../../../sections/@dashboard/invoice/details/InvoicePDF';
import { pdf } from '@react-pdf/renderer';
import { COMPANY_DATA_FOR_PDF } from '../../../../config';
import { accessVerify, capitalize, handleNumber, safeJSONParse } from '../../../../utils/common';
import PermissionRequired from '../../../errorPages/permissionRequired';
import { PictureAsPdf } from '@material-ui/icons';
import { getAllStockReturns, updateStockReturnStatus } from '../../../../redux/slices/stockManagementRedux/stockReturnRedux';
import utcMoment from 'moment';
import Iconify from '../../../../components/Iconify';

export default function ViewReturns() {
  const { themeStretch } = useSettings();
  const [returnsList, setReturnsList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const { data, addData } = useSelector((state) => state?.stockReturn);
  const { selectedLocation } = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(getAllStockReturns());
  }, []);

  useEffect(() => {
    setReturnsList(data?.map(value=> {
      return {
        date: utcMoment(value?.dateTime).format('YYYY-MM-DD'),
        time: utcMoment(value?.dateTime).format('HH:mm:ss'),
        ...value,
        supplierInfo: safeJSONParse(value?.supplierInfo),
        userInfo: safeJSONParse(value?.userInfo),
        stockDetails: safeJSONParse(value?.stockDetails),
      }
    }));
  }, [data]);

  useEffect(() => {
    if (addData?.time) {
      dispatch(getAllStockReturns());
    }
  }, [addData]);

  const getItemsForInvoice = (rowData) => {
    if (rowData) {
      return rowData?.map((value, index) => {
        return {
          id: index,
          title: value?.food?.name,
          foodId: value?.food?.foodId,
          description: value?.code,
          quantity: value?.currentQty,
          storeUnitQty: value?.currentQty,
          price: value?.cost,
        };
      });
    }
  };

  const handleDownload = async (row) => {
    const rowData = row?.original;
    const pdfBlob = await pdf(
      <InvoicePDF
        invoice={{
          invoiceNumber: `Return-${rowData?.returnNoteId}`,
          createDate: utcMoment(row?.original?.date).format('DD MMMM yyyy').toString(),
          dueDate: utcMoment(row?.original?.date).add(7, 'days').format('DD MMMM yyyy').toString(),
          status: rowData?.status,
          discount: 0,
          taxes: 0,
          paidAmount: handleNumber(rowData?.paidAmount),
          totalPrice: handleNumber(rowData?.amount) - handleNumber(rowData?.paidAmount),
          subTotalPrice: handleNumber(rowData?.amount),
          topic: 'Return Note',
          invoiceFrom: {
            name: COMPANY_DATA_FOR_PDF?.name,
            address: selectedLocation?.address,
            phone: selectedLocation?.contactNumber,
          },
          invoiceTo: {
            name: rowData?.supplierInfo?.name,
            address: rowData?.supplierInfo?.address,
            phone: rowData?.supplierInfo?.companyPhone,
          },
          items: getItemsForInvoice(rowData?.stockDetails),
          stockCodes: safeJSONParse(rowData?.stockCodes) || []
        }}
      />
    ).toBlob();

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `Return-Note-${rowData?.returnNoteId}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfUrl);
  };

  const columns = !returnsList?.[0]
    ? []
    : Object.keys(returnsList?.[0])
      .map((value) => {
        if (
          value === 'dateTime' ||
          value === 'userId' ||
          value === 'financeConfirmedUserId' ||
          value === 'supplierId' ||
          value === 'supplierInfo' ||
          value === 'userInfo' ||
          value === 'stockDetails'
        ) {
          return null;
        } else if (value === 'amount' || value === 'paidAmount') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ row }) => fCurrency(row?.original?.amount),
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      .filter((value) => value);

  return (
    <Page title='View Returns'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='View Returns'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Returns',
              href: PATH_DASHBOARD.stocks.root,
            },
            { name: 'View Returns' },
          ]}
          action={[]}
        />

        {accessVerify('PURCHASE_NOTE_VIEW') ? (
          <DataGridTable
            name={'View Returns'}
            data={returnsList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(row, closeMenu) => [
              <div style={{ minWidth: '100px' }}>
                <Tooltip title='Download PDF'>
                  <IconButton color="error" onClick={() => {
                    handleDownload(row);
                  }}>
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
                {row?.original?.status === 'PENDING' && (
                  <>
                  <Tooltip title='Approve return note'>
                    <IconButton color="success" onClick={() => {
                      dispatch(
                        updateStockReturnStatus({
                          id: row?.original?.returnNoteId,
                          status: 'FINANCE_CONFIRMED',
                          selectedLocationId: selectedLocation?.id,
                        })
                      );
                    }}>
                      <Iconify icon={'line-md:confirm-circle-filled'} width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Cancel return note'>
                    <IconButton color="error" onClick={() => {
                      dispatch(
                        updateStockReturnStatus({
                          id: row?.original?.returnNoteId,
                          status: 'FINANCE_DELETED',
                          selectedLocationId: selectedLocation?.id,
                        })
                      );
                    }}>
                      <Iconify icon={'line-md:close-circle-filled'} width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                  </>
                )}
              </div>,
            ]}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
