import React, { useEffect, useState } from 'react';
import {
  Autocomplete, Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { addFood, getAllFoods, getFoodAveragePricingForStock, getFoods, updateFood } from '../../../redux/slices/stockManagementRedux/foodRedux';
import { getUnits } from '../../../redux/slices/stockManagementRedux/unitRedux';
import { getFoodCategorys } from '../../../redux/slices/stockManagementRedux/foodCategoryRedux';
import { getAllSaleTypes } from '../../../redux/slices/saleRedux/salesRedux';
import ExField from '../../../components/expergen/form/ExField';
import ExSwitch from '../../../components/expergen/form/ExSwitch';
import { capitalCase } from 'change-case';
import useTabs from '../../../hooks/useTabs';
import { accessVerify, jsonParseArray } from '../../../utils/common';
import { CloseIcon } from '../../../theme/overrides/CustomIcons';
import EditIcon from '@material-ui/icons/Edit';
import { fCurrency } from '../../../utils/formatNumber';
import UploadMultiFile from '../../../components/upload/UploadMultiFile';
import { imageUpload } from '../../../inteceptor';
import { toast } from 'react-toastify';
import { getLocations } from '../../../redux/slices/settingsManagementRedux/locationsRedux';

export default function FoodEditor() {
  const navigate = useNavigate();
  let { type, foodId } = useParams();
  const { themeStretch } = useSettings();
  const { loggedInUser } = useSelector((state) => state?.user);

  const [dataModel, setDataModel] = useState({
    foodBible: [],
    code: '',
    image: '',
    description: '',
    foodCategoryId: '',
    isBeverage: false,
    isVariation: false,
    isVegetarian: false,
    isUsedEligible: false,
    isWasteEligible: false,
    isMissingEligible: false,
    locationData: [],
    name: '',
    saleTypes: [],
    variation: [],
    unitId: '',
  });

  const [dataUpdated, setDataUpdated] = useState(false);
  const [recipesFoodList, setRecipesFoodList] = useState([]);
  let addData;
  let allData;
  let averageFoodPricesForStock;
  ({ addData, allData, averageFoodPricesForStock } = useSelector((state) => state?.food));
  let foodCategoryData;
  ({ data: foodCategoryData } = useSelector((state) => state?.foodCategory));
  let units;
  ({ data: units } = useSelector((state) => state?.unit));
  let locationData;
  ({ data: locationData } = useSelector((state) => state.location));
  const { saleTypes } = useSelector((state) => state.sales);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    dispatch(getLocations());
    dispatch(getUnits());
    dispatch(getAllSaleTypes());
    dispatch(getFoodAveragePricingForStock());
  }, []);

  useEffect(() => {
    dispatch(getAllFoods());
    dispatch(getFoods(type));
    dispatch(getFoodCategorys(type));
  }, [type]);

  useEffect(() => {
    const isDataLoaded = allData && foodCategoryData && units && saleTypes && locationData;

    if (foodId !== 'new' && isDataLoaded) {
      try {
        const data = allData?.find((value) => value?.id === Number(foodId));
        if (!data) {
          console.warn(`No data found for foodId: ${foodId}`);
          return;
        }

        const foodCategory = foodCategoryData?.find((value) => value?.id === data?.foodCategoryId) || {};
        const foodUnit = units?.find((value) => value?.id === data?.unitId) || {};
        const recipeData = jsonParseArray(data?.recipeData) || [];
        const enteredLocationData = jsonParseArray(data?.locationData) || [];
        const enteredVariationList = jsonParseArray(data?.variation) || [];
        const pricingData = jsonParseArray(data?.pricingData) || [];
        const supplierData = jsonParseArray(data?.supplierData) || [];

        const foodBible = recipeData.map((value) => {
          const foodData = allData?.find((foodValue) => foodValue?.id === value?.ingredientId) || {};
          return {
            deliveryQty: value?.deliveryQty || 0,
            description: foodData?.description || '',
            diningQty: value?.diningQty || 0,
            id: value?.ingredientId || null,
            name: foodData?.name || '',
            takeAwayQty: value?.takeAwayQty || 0,
            unitName: units?.find((unitElement) => unitElement?.id === foodData?.unitId)?.name || '',
          };
        });

        setDataModel({
          id: data?.id || null,
          code: data?.code || '',
          image: data?.image || '',
          description: data?.description || null,
          isBeverage: data?.isBeverage || false,
          isVariation: data?.isVariation || false,
          isVegetarian: data?.isVegetarian || false,
          isUsedEligible: data?.isUsedEligible || false,
          isWasteEligible: data?.isWasteEligible || false,
          isMissingEligible: data?.isMissingEligible || false,
          name: data?.name || '',
          foodCategoryId: data?.foodCategoryId || null,
          supplierData: supplierData || [],
          foodCategoryIdObj: {
            label: `${foodCategory?.name || ''} - ${foodCategory?.description || ''}`,
            value: data?.foodCategoryId,
          },
          foodBible: foodBible,
          locationData: locationData.map((value) => ({
            id: value?.id || null,
            name: value?.name || '',
            status: enteredLocationData.some((enteredLocationElement) => enteredLocationElement?.locationID === value?.id),
            minimumQty:
              enteredLocationData.find((enteredLocationElement) => enteredLocationElement?.locationID === value?.id)?.minQty || null,
          })),
          saleTypes: saleTypes.map((value) => ({
            ...value,
            amount: pricingData.find((pricingValue) => pricingValue?.saleTypeId === value?.id)?.pricing || null,
          })),
          unitId: data?.unitId || null,
          unitIdObj: {
            label: `${foodUnit?.name || ''} (${foodUnit?.symbol || ''}) - ${foodUnit?.description || ''}`,
            value: foodUnit?.id || null,
          },
          variation: enteredVariationList.map((value) => {
            const newValue = allData?.find((allDataValue) => allDataValue?.id === value?.variationId) || {};
            return {
              variationId: value?.variationId || null,
              label: `${newValue?.name || ''} - ${newValue?.description || ''}`,
              value: value?.variationId || null,
            };
          }) || [],
        });
      } catch (e) {
        console.error("Error processing data:", e);
      }
    } else {
      if (locationData) {
        setDataModel((prevDataModel) => ({
          ...prevDataModel,
          locationData: locationData.map((value) => ({
            id: value?.id,
            name: value?.name,
            status: false,
            minimumQty: null,
          })),
          saleTypes: saleTypes.map((value) => ({
            ...value,
            amount: null,
          })),
        }));
      }
    }
  }, [foodCategoryData, allData, foodId, saleTypes, locationData, units]);

  useEffect(() => {
    if (units) {
      setRecipesFoodList(
        allData
          ?.filter((value) => ['ingredient', 'precooked'].includes(value?.type))
          ?.map((foodItem) => {
            return {
              ...foodItem,
              unitName: units.filter((value) => value?.id === foodItem.unitId)?.[0]?.name,
            };
          })
      );
    }
  }, [units, allData]);

  useEffect(() => {
    if (addData?.data) {
      if (dataUpdated) {
        if (type === 'variation') {
          window.close();
        } else {
          navigate(`/dashboard/purchasing/foods/types/${type}`);
        }
      }
    }
  }, [addData]);
  useEffect(() => {
    handleUpdate('isVariation', isVariationMainProduct());
  }, [dataModel?.variation]);

  const handleUpdate = (type, value) => {
    setDataModel((prevDataModel) => ({
      ...dataModel,
      [type]: value,
    }));
  };
  const handleFoodBibleInputUpdate = (type, value) => {
    const splitType = type.split('_');
    handleUpdate(
      'foodBible',
      dataModel.foodBible.map((foodBibleElement) => {
        if (Number(foodBibleElement?.id) === Number(splitType[1])) {
          return { ...foodBibleElement, [splitType[0]]: Number(value) };
        } else {
          return foodBibleElement;
        }
      })
    );
  };
  const handleFoodBibleInputForPreMadeUpdate = (type, value) => {
    const splitType = type.split('_');
    handleUpdate(
      'foodBible',
      dataModel.foodBible.map((foodBibleElement) => {
        if (Number(foodBibleElement?.id) === Number(splitType[1])) {
          return {
            ...foodBibleElement,
            diningQty: Number(value),
            deliveryQty: Number(value),
            takeAwayQty: Number(value),
          };
        } else {
          return foodBibleElement;
        }
      })
    );
  };
  const handleLocationUpdate = (type, value) => {
    const splitType = type.split('_');
    handleUpdate(
      'locationData',
      dataModel.locationData.map((locationIndex) => {
        if (Number(locationIndex?.id) === Number(splitType[1])) {
          return { ...locationIndex, [splitType[0]]: value };
        } else {
          return locationIndex;
        }
      })
    );
  };
  const handlePricingUpdate = (type, value) => {
    const splitType = type.split('_');
    handleUpdate(
      'saleTypes',
      dataModel.saleTypes.map((salesIndex) => {
        if (Number(salesIndex?.id) === Number(splitType[1])) {
          return { ...salesIndex, [splitType[0]]: value };
        } else {
          return salesIndex;
        }
      })
    );
  };

  const { currentTab, onChangeTab } = useTabs('all');
  const isVariationMainProduct = () => dataModel?.variation?.length > 0;

  const ACCOUNT_TABS = [
    {
      value: 'all',
      icon: <Iconify icon={'bi:house-door'} width={20} height={20} />,
      types: ['menu', 'precooked', 'ingredient', 'variation'],
    },
    {
      value: 'information',
      icon: <Iconify icon={'bi:person'} width={20} height={20} />,
      types: ['menu', 'precooked', 'ingredient', 'variation'],
    },
    {
      value: 'bible',
      icon: <Iconify icon={'bi:book'} width={20} height={20} />,
      types: isVariationMainProduct() ? ['precooked'] : ['menu', 'precooked'],
    },
    {
      value: 'variation',
      icon: <Iconify icon={'fa-solid:dice'} width={20} height={20} />,
      types: ['menu', 'variation'],
    },
    {
      value: 'pricing',
      icon: <Iconify icon={'bi:credit-card'} width={20} height={20} />,
      types: isVariationMainProduct() ? [] : ['menu'],
    },
    {
      value: 'location_based_information',
      icon: <Iconify icon={'bi:map'} width={20} height={20} />,
      types: isVariationMainProduct() ? ['precooked', 'ingredient'] : ['menu', 'precooked', 'ingredient'],
    },
  ];

  const updateDatabase = () => {
    console.log('loggedInUser',loggedInUser);
    setDataUpdated(true);
    if (foodId === 'new') {
      dispatch(addFood({ ...dataModel, type: type }));
    } else {
      if(accessVerify("SUPER_ADMIN") || loggedInUser?.id === 50){
        dispatch(updateFood({ ...dataModel, type: type }));
      } else {
        toast.error('Only super admin can edit.')
      }
    }
  };


  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        imageUpload(
          file,
          'food',
          (data) => {
            setIsUploading(false);
            if (data?.status) {
              handleUpdate('image', data?.url);
            } else {
            }
          },
          (errorMessage) => {
            setIsUploading(false);
            console.error('Upload failed:', errorMessage);
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  };
  return (
    <Page title={foodId === 'new' ? `Add New Food` : `Update ${dataModel?.name}`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={foodId === 'new' ? `Add New Food` : `Update ${dataModel?.name}`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Stock',
              href: '',
            },
            {
              name: foodId === 'new' ? `Add New Food` : `Update ${dataModel?.name}`,
            },
          ]}
          action={[
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:save-fill' />}
              onClick={() => {
                updateDatabase();
              }}
            >
              Save
            </Button>,
          ]}
        />

        <Tabs allowScrollButtonsMobile variant='scrollable' scrollButtons='auto' value={currentTab} onChange={onChangeTab}>
          {ACCOUNT_TABS.map((tab) => tab?.types?.includes(type) && <Tab disableRipple key={tab.value} label={capitalCase(tab.value)} icon={tab.icon} value={tab.value} />)}
        </Tabs>
        <Grid container spacing={3}>
          <Grid item xs={12} md={currentTab === 'all' ? (isVariationMainProduct() ? 12 : 8) : 12}>
            <Stack spacing={3}>
              {['all', 'information'].includes(currentTab) && (
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Food Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <ExField label={'Name'} code={'name'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ExField label={'Code'} code={'code'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <ExField label={'Description'} code={'description'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          setDataModel((prevDataModel) => ({
                            ...dataModel,
                            foodCategoryId: newValue?.value,
                            foodCategoryIdObj: newValue
                          }));
                        }}
                        options={foodCategoryData.map((value) => ({
                          label: `${value.name} - ${value.description}`,
                          value: value.id,
                        }))}
                        value={dataModel?.foodCategoryIdObj || null}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField label='Food Category' {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          setDataModel((prevDataModel) => ({
                            ...dataModel,
                            unitId: newValue?.value,
                            unitIdObj: newValue
                          }));
                        }}
                        options={units.map((value) => ({
                          label: `${value.name} (${value.symbol}) - ${value.description}`,
                          value: value.id,
                        }))}
                        value={dataModel?.unitIdObj || null}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField label='Unit Type' {...params} />}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <ExSwitch label={'Is Beverage'} code={'isBeverage'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <ExSwitch label={'Is Vegetarian'} code={'isVegetarian'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>
                    <Grid item xs={12} md={3}></Grid>
                    <Grid item xs={12} md={3}>
                      <ExSwitch label={'Is Used Allowed'} code={'isUsedEligible'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <ExSwitch label={'Is Waste Allowed'} code={'isWasteEligible'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <ExSwitch label={'Is Missing Allowed'} code={'isMissingEligible'} dataModel={dataModel} handleUpdate={handleUpdate} />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <UploadMultiFile
                        error={false}
                        showPreview={true}
                        files={files}
                        onRemove={(removedFile) => {
                          setFiles(files.filter((file) => file !== removedFile));
                        }}
                        onRemoveAll={() => {
                          setFiles([]);
                        }}
                        helperText={''}
                        onDrop={(uploadedFiles) => {
                          setFiles((value) => [
                            ...value,
                            ...uploadedFiles.map((file, index) =>
                              Object.assign(file, {
                                id: index,
                                preview: URL.createObjectURL(file),
                                isUploading: false,
                              })
                            ),
                          ]);
                        }}
                        onUpload={() => {
                          if (files?.length === 1) {
                            handleImageUpload();
                          } else {
                            toast.error('Only one image!');
                          }
                        }}
                        isUploading={isUploading}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <img src={dataModel?.image} style={{ width: '100px' }} />
                    </Grid>

                    {dataModel?.supplierData && <><Box sx={{ p: 2 }}>
                      <br/>
                      <br/>
                      <Typography variant="h5" gutterBottom>
                        Linked Suppliers
                      </Typography>
                      <Grid container spacing={2}>
                        {dataModel?.supplierData?.map((supplierData, index) => (
                          <Grid item xs={12} md={6} key={index}>
                            <Card variant="outlined" sx={{ height: '100%' }}>
                              <CardContent>
                                <Typography variant="h6" color="primary" gutterBottom>
                                  {supplierData.supplier.name}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  <strong>Code:</strong> {supplierData.supplier.code}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  <strong>Supplier ID:</strong> {supplierData.supplierId}
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                  <strong>Phone:</strong> {supplierData.supplier.companyPhone}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    </Box></>}
                  </Grid>
                </Card>
              )}
              {['all', 'bible'].includes(currentTab) && ['variation', 'menu', 'precooked'].includes(type) && !isVariationMainProduct() && (
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Food Bible Information
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          handleUpdate('foodBible', [
                            ...dataModel?.foodBible,
                            {
                              id: newValue?.id,
                              name: newValue?.name,
                              description: newValue?.description,
                              unitName: newValue?.unitName,
                              diningQty: '',
                              deliveryQty: '',
                              takeAwayQty: '',
                            },
                          ]);
                        }}
                        options={recipesFoodList
                          ?.filter((value) => dataModel?.foodBible?.filter((selectedFood) => selectedFood?.id === value?.id)?.length === 0)
                          ?.map((value) => {
                            return {
                              ...value,
                              label: `${value?.name} (${value?.unitName}) - ${value?.description}`,
                              value: value?.id,
                            };
                          })}
                        value={null}
                        renderInput={(params) => <TextField label='Select Precook or Ingredient' {...params} fullWidth />}
                      />
                    </Grid>
                    {dataModel?.foodBible?.map((value, index) => {
                      return (
                        <>
                          <Grid item xs={3} md={3}>
                            <Typography variant='subtitle1' padding='10px 0'>
                              {' '}
                              {value?.name} <sub>(AVG: {fCurrency(averageFoodPricesForStock?.find((priceValue) => priceValue?.foodId === value?.id)?.averageCost || 0)})</sub>
                            </Typography>
                          </Grid>
                          {type === 'precooked' ? (
                            <>
                              <Grid item xs={9} md={9}>
                                <ExField
                                  label={`Qty (${value?.unitName})`}
                                  code={`diningQty_${value?.id}`}
                                  value={value?.diningQty}
                                  handleUpdate={handleFoodBibleInputForPreMadeUpdate}
                                  type={'number'}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={3} md={3}>
                                <ExField label={`Dining Qty (${value?.unitName})`} code={`diningQty_${value?.id}`} value={value?.diningQty} handleUpdate={handleFoodBibleInputUpdate} type={'number'} />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <ExField
                                  label={`Delivery Qty (${value?.unitName})`}
                                  code={`deliveryQty_${value?.id}`}
                                  value={value?.deliveryQty}
                                  handleUpdate={handleFoodBibleInputUpdate}
                                  type={'number'}
                                />
                              </Grid>
                              <Grid item xs={3} md={3}>
                                <ExField
                                  label={`Take Away Qty (${value?.unitName})`}
                                  code={`takeAwayQty_${value?.id}`}
                                  value={value?.takeAwayQty}
                                  handleUpdate={handleFoodBibleInputUpdate}
                                  type={'number'}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      );
                    })}
                  </Grid>
                </Card>
              )}

              {['all', 'variation'].includes(currentTab) && ['menu'].includes(type) && (
                <Card sx={{ p: 3 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <Typography variant='subtitle1' padding='10px 0' style={{ flex: 1 }}>
                      Variation Information
                    </Typography>
                    <Button
                      variant='contained'
                      startIcon={<Iconify icon='eva:plus-fill' />}
                      onClick={() => {
                        const variationWindow = window.open(`/dashboard/purchasing/foods/food/variation/manage/new`, 'variation', 'width=1900,height=1500, top=50, left=50');
                        const timer = setInterval(function () {
                          if (variationWindow.closed) {
                            clearInterval(timer);
                            dispatch(getAllFoods());
                          }
                        }, 1000);
                      }}
                    >
                      Variation
                    </Button>
                  </div>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          handleUpdate('variation', [
                            ...dataModel?.variation,
                            {
                              variationId: newValue?.id,
                              label: `${newValue?.name} - ${newValue?.description}`,
                              value: newValue?.id,
                            },
                          ]);
                        }}
                        options={allData
                          ?.filter((value) => value?.type === 'variation' && !dataModel?.variation?.map((variationList) => variationList?.variationId).includes(value?.id))
                          ?.map((value) => {
                            return {
                              ...value,
                              label: `${value?.name} - ${value?.description}`,
                              value: value?.id,
                            };
                          })}
                        value={null}
                        renderInput={(params) => <TextField label='Select Variation From Menu' {...params} fullWidth />}
                      />
                    </Grid>
                    <br />
                    {dataModel?.variation?.map((value, index) => (
                      <Card
                        key={index}
                        sx={{
                          width: '90%',
                          padding: '3%',
                          margin: '1% 5%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant='subtitle1' padding='10px 0'>
                          {' '}
                          {value?.label}{' '}
                        </Typography>
                        <div>
                          <IconButton
                            onClick={() => {
                              const variationWindow = window.open(`/dashboard/purchasing/foods/food/variation/manage/${value?.variationId}`, 'variation', 'width=1900,height=1500, top=50, left=50');
                              const timer = setInterval(function () {
                                if (variationWindow.closed) {
                                  clearInterval(timer);
                                  dispatch(getAllFoods());
                                }
                              }, 1000);
                            }}
                            style={{ cursor: 'pointer', marginTop: '1%' }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleUpdate(
                                'variation',
                                dataModel?.variation?.filter((enteredVariations) => enteredVariations?.variationId !== value?.variationId)
                              );
                            }}
                            style={{ cursor: 'pointer', marginTop: '1%' }}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      </Card>
                    ))}
                  </Grid>
                </Card>
              )}
            </Stack>
          </Grid>

          {!isVariationMainProduct() && (
            <Grid item xs={12} md={currentTab === 'all' ? 4 : 12}>
              <Stack spacing={3}>
                {['all', 'pricing'].includes(currentTab) && ['menu', 'variation'].includes(type) && (
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Pricing Details
                    </Typography>
                    <Grid container spacing={3}>
                      {dataModel?.saleTypes?.map((value) => {
                        return (
                          <>
                            <Grid item xs={12} md={12}>
                              <ExField label={`${value?.name} Price Per Unit`} code={`amount_${value?.id}`} value={value?.amount} handleUpdate={handlePricingUpdate} />
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Card>
                )}
                {['all', 'location_based_information'].includes(currentTab) && ['ingredient', 'precooked', 'menu', 'variation'].includes(type) && (
                  <Card sx={{ p: 3 }}>
                    <Typography variant='subtitle1' padding='10px 0'>
                      Locations Based Information
                    </Typography>
                    <Grid container spacing={3}>
                      {dataModel?.locationData?.map((value) => {
                        return (
                          <>
                            <Grid item xs={12} md={4}>
                              <ExSwitch label={value?.name} code={`status_${value?.id}`} value={value?.status} handleUpdate={handleLocationUpdate} sx={{ mt: '10px' }} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                              <ExField label={`Minimum Stock Qty for ${value?.name}`} code={`minimumQty_${value?.id}`} value={value?.minimumQty} handleUpdate={handleLocationUpdate} />
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Card>
                )}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}
