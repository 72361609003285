import React, { useEffect, useState } from 'react';
import {
  Container,
  IconButton,
  Tooltip,
} from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { fCurrency } from '../../../utils/formatNumber';
import { deletePurchaseNotes, getPurchaseNotes, updatePurchaseNotes } from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import InvoicePDF from '../../../sections/@dashboard/invoice/details/InvoicePDF';
import { BASE_URL, COMPANY_DATA_FOR_PDF } from '../../../config';
import { accessVerify, capitalize, handleNumber, safeJSONParse, utcMoment } from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';
import { ORDER_STATUS } from '../../../utils/constants';
import { pdf } from '@react-pdf/renderer';
import { Block, CheckCircleOutline, PictureAsPdf } from '@material-ui/icons';

export default function FinanceConfirmation() {
  const { themeStretch } = useSettings();
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);

  const { data, addData } = useSelector((state) => state?.purchaseNotes);
  const { selectedLocation } = useSelector((state) => state?.location);

  let stockUpdated;
  ({ addData: stockUpdated } = useSelector((state) => state?.stock));

  useEffect(() => {
    dispatch(getPurchaseNotes());
  }, []);

  useEffect(() => {
    setPurchaseNotesList(
      data.map((value) => {
        return {
          day: utcMoment(value?.date).format('YYYY-MM-DD'),
          time: utcMoment(value?.date).format('hh:mm A'),
          ...value,
          linkedDocuments: value?.linkedDocuments ? safeJSONParse(value?.linkedDocuments)?.map(value=> value?.url) : [],
          itemList: safeJSONParse(value?.supplierorder_foodsupplierpricing).map((value) => {
            return {
              ...value,
              jsonData: { ...safeJSONParse(value?.jsonData), receivings: [] },
            };
          }),
        };
      })
    );
  }, [data]);

  useEffect(() => {
    if (stockUpdated?.data, addData?.data) {
      dispatch(getPurchaseNotes());
    }
  }, [stockUpdated, addData]);

  const getItemsForInvoice = (rowData) => {
    if (rowData?.supplierorder_foodsupplierpricing) {
      const parsedRowData = safeJSONParse(rowData?.supplierorder_foodsupplierpricing);
      return parsedRowData?.map((value, index) => {
        const jsonData = safeJSONParse(value?.jsonData);
        const pricePerSupplierUnit = (jsonData?.price / jsonData?.purchaseUnitQty) * jsonData?.storeUnitQty;
        return {
          id: index,
          title: jsonData?.FoodName,
          foodId: value?.foodId,
          description: `Final Price is checked on ${jsonData?.formatted_dateTime}.`,
          quantity: Number(value?.receivedQty) / (Number(value?.storeQty) / Number(value?.supplierQty)),
          storeUnitQty: jsonData?.storeUnitQty + ' ' + jsonData?.unitSymbol,
          price: pricePerSupplierUnit,
        };
      });
    }
  };

  const columns = !purchaseNotesList?.[0]
    ? []
    : Object.keys(purchaseNotesList?.[0])
      .map((value) => {
        if (
          value === 'itemList' ||
          value === 'supplierAddress' ||
          value === 'supplierCode' ||
          value === 'supplierCompanyPhone' ||
          value === 'paymentMethod' ||
          value === 'paymentDate' ||
          value === 'estimatedreceivingdate' ||
          value === 'orderedById' ||
          value === 'paymentmethod' ||
          value === 'paymentdate' ||
          value === 'supplierorder_foodsupplierpricing'
        ) {
          return null;
        } else if (value === 'supplierId') {
          return {
            accessorKey: 'supplierId',
            header: 'Invoice Id',
            Cell: ({ row }) => `INV-${row?.original?.id}`,
          };
        } else if (value === 'totalamount') {
          return {
            accessorKey: 'totalamount',
            header: 'Total Amount',
            Cell: ({ row }) => fCurrency(row?.original?.totalamount),
          };
        } else if (value === 'paymentforreceivings') {
          return {
            accessorKey: 'paymentforreceivings',
            header: 'Payment For Receivings',
            Cell: ({ row }) => fCurrency(row?.original?.paymentforreceivings),
          };
        } else if (value === 'paidamount') {
          return {
            accessorKey: 'paidamount',
            header: 'Paid Amount',
            Cell: ({ row }) => fCurrency(row?.original?.paidamount),
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      .filter((value) => value);

  const handleDownload = async (row) => {
    const pdfBlob = await pdf(
      <InvoicePDF
        invoice={{
          invoiceNumber: `GRN-${row?.original?.id}`,
          createDate: utcMoment(row?.original?.date).format('YYYY-MM-DD HH:mm:ss'),
          dueDate: utcMoment(row?.original?.date).add(30,'days').format('YYYY-MM-DD HH:mm:ss'),
          status: row?.original?.status,
          discount: 0,
          taxes: 0,
          paidAmount: handleNumber(row?.original?.paidAmount),
          totalPrice: handleNumber(row?.original?.paymentforreceivings) - handleNumber(row?.original?.paidAmount),
          subTotalPrice: handleNumber(row?.original?.paymentforreceivings),
          topic: 'Good Receiving Notes',
          invoiceFrom: {
            name: COMPANY_DATA_FOR_PDF?.name,
            address: selectedLocation?.address,
            phone: selectedLocation?.contactNumber,
          },
          invoiceTo: {
            name: row?.original?.supplierName,
            address: row?.original?.supplierAddress,
            phone: row?.original?.supplierCompanyPhone,
          },
          items: getItemsForInvoice(row?.original),
          stockCodes: safeJSONParse(row?.original?.stockCodes) || [],
          linkedDocuments: row?.original?.linkedDocuments?.map(value=> value?.replace("https://storage.googleapis.com/bubblemania/", `${BASE_URL}imageViewer?path=`)) || []

        }}
      />
    ).toBlob();

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `GRN-${row?.original?.id}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfUrl);
  };
  return (
    <Page title='Receiving'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Receiving'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Receiving',
              href: PATH_DASHBOARD.stocks.root,
            },
            { name: 'Receiving' },
          ]}
          action={[]}
        />

        {accessVerify('GOODS_RECEIVING_VIEW') || accessVerify('PURCHASE_NOTE_VIEW') ? (
          <DataGridTable
            name={'Receivings'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(row) => {
              return [
                <>
                  <Tooltip title='Download PDF'>
                    <IconButton color="error" onClick={() => {
                      handleDownload(row);
                    }}>
                      <PictureAsPdf />
                    </IconButton>
                  </Tooltip>

                  {row?.original?.status === ORDER_STATUS.PENDING_FINANCE_CONFIRMATION.code && (
                    <Tooltip title='Set Confirmed by Finance'>
                      <IconButton
                        color="success"
                        onClick={() => {
                          dispatch(
                            updatePurchaseNotes({
                              id: row?.original?.id,
                              status: 'RECEIVED',
                            })
                          );
                        }}
                      >
                        <CheckCircleOutline />
                      </IconButton>
                    </Tooltip>
                  )}
                  {row?.original?.status === ORDER_STATUS.PENDING_FINANCE_CONFIRMATION.code && (
                    <Tooltip title='Delete Purchase Note'>
                      <IconButton
                        color="warning"
                        onClick={() => {
                          dispatch(
                            deletePurchaseNotes({
                              id: row?.original?.id
                            })
                          );
                        }}
                      >
                        <Block />
                      </IconButton>
                    </Tooltip>
                  )}
                </>,
              ];
            }}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
