function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/home'),
  dashboard: {
    root: path(ROOTS_DASHBOARD, '/home'),
    meta: {
      root: path(ROOTS_DASHBOARD, '/home/meta'),
      sales: path(ROOTS_DASHBOARD, '/home/meta/sales'),
      employees: path(ROOTS_DASHBOARD, '/home/meta/employees'),
      stockChecker: path(ROOTS_DASHBOARD, '/home/meta/stockChecker'),
      stockDashboard: path(ROOTS_DASHBOARD, '/home/meta/stockDashboard'),
    },
  },

  purchasing: {
    root: path(ROOTS_DASHBOARD, '/purchasing'),
    priceChecker: path(ROOTS_DASHBOARD, '/purchasing/enterPrices'),
    addToCart: path(ROOTS_DASHBOARD, '/purchasing/addToCart'),
    purchaseNotes: path(ROOTS_DASHBOARD, '/purchasing/purchaseNotes'),
    receiving: path(ROOTS_DASHBOARD, '/purchasing/receiving'),
    financeConfirmation: path(ROOTS_DASHBOARD, '/purchasing/financeConfirmation'),
    stockSupplier: path(ROOTS_DASHBOARD, '/purchasing/suppliers/stockSupplier'),
    foods: {
      root: path(ROOTS_DASHBOARD, '/purchasing/foods'),
      foodCategory: {
        root: path(ROOTS_DASHBOARD, '/purchasing/foods/category'),
        ingredient: path(ROOTS_DASHBOARD, '/purchasing/foods/category/ingredient'),
        precooked: path(ROOTS_DASHBOARD, '/purchasing/foods/category/precooked'),
        menu: path(ROOTS_DASHBOARD, '/purchasing/foods/category/menu'),
        variation: path(ROOTS_DASHBOARD, '/purchasing/foods/category/variation'),
      },
      foodTypes: {
        root: path(ROOTS_DASHBOARD, '/purchasing/foods/types'),
        ingredient: path(ROOTS_DASHBOARD, '/purchasing/foods/types/ingredient'),
        precooked: path(ROOTS_DASHBOARD, '/purchasing/foods/types/precooked'),
        menu: path(ROOTS_DASHBOARD, '/purchasing/foods/types/menu'),
        variation: path(ROOTS_DASHBOARD, '/purchasing/foods/types/variation'),
      },
    },
  },
  stocks: {
    root: path(ROOTS_DASHBOARD, '/stock'),
    singleStockItem: path(ROOTS_DASHBOARD, '/stock/single-stock-item'),
    viewAll: {
      root: path(ROOTS_DASHBOARD, '/stock/view-all'),
      viewAll: path(ROOTS_DASHBOARD, '/stock/view-all/full'),
      viewPOS: path(ROOTS_DASHBOARD, '/stock/view-all/sold'),
      wasteOnly: path(ROOTS_DASHBOARD, '/stock/view-all/waste'),
      missing: path(ROOTS_DASHBOARD, '/stock/view-all/missing'),
      misplaceStockList: path(ROOTS_DASHBOARD, '/stock/misplaceStockList'),
      usedOnly: path(ROOTS_DASHBOARD, '/stock/view-all/used'),
      returnOnly: path(ROOTS_DASHBOARD, '/stock/view-all/return'),
    },
    food: path(ROOTS_DASHBOARD, '/stock/food'),
    foodCategory: path(ROOTS_DASHBOARD, '/stock/foodCategory'),
    supplier: path(ROOTS_DASHBOARD, '/stock/supplier'),
    viewTransfer: path(ROOTS_DASHBOARD, '/stock/view-transfer'),
    breaker: path(ROOTS_DASHBOARD, '/stock/breaker'),
    qtyChanger: path(ROOTS_DASHBOARD, '/stock/qtyChanger'),
    stockManager: {
      root: path(ROOTS_DASHBOARD, '/stock/stockManager'),
      wasteManager: path(ROOTS_DASHBOARD, '/stock/stockManager/wasteManager'),
      usedStockManager: path(ROOTS_DASHBOARD, '/stock/stockManager/usedStockManager'),
      returnManager: path(ROOTS_DASHBOARD, '/stock/stockManager/returnManager'),
      missingManager: path(ROOTS_DASHBOARD, '/stock/stockManager/missingManager'),
    },
    transfer: {
      root: path(ROOTS_DASHBOARD, '/stock/transfer'),
      view: path(ROOTS_DASHBOARD, '/stock/transfer/view'),
      sender: path(ROOTS_DASHBOARD, '/stock/transfer/sender'),
      receiver: path(ROOTS_DASHBOARD, '/stock/transfer/receiver'),
    },
    return: {
      root: path(ROOTS_DASHBOARD, '/stock/return'),
      creator: path(ROOTS_DASHBOARD, '/stock/return/creator'),
      view: path(ROOTS_DASHBOARD, '/stock/return/view'),
    },
    stockOrder: {
      root: path(ROOTS_DASHBOARD, '/stock/stockOrder'),
      view: path(ROOTS_DASHBOARD, '/stock/stockOrder/view'),
      create: path(ROOTS_DASHBOARD, '/stock/stockOrder/create'),
    },
    availabilityChecker: {
      checker: path(ROOTS_DASHBOARD, '/stock/availability/checker'),
      report: path(ROOTS_DASHBOARD, '/stock/availability/report'),
    },
  },
  suppliers: {
    root: path(ROOTS_DASHBOARD, '/suppliers'),
    stockSupplier: path(ROOTS_DASHBOARD, '/suppliers/stockSupplier'),
  },

  sales: {
    root: path(ROOTS_DASHBOARD, '/sales'),
    saleList: path(ROOTS_DASHBOARD, '/sales/saleList'),
    customerList: path(ROOTS_DASHBOARD, '/sales/customerList'),
  },
  hr: {
    root: path(ROOTS_DASHBOARD, '/hr'),
    employees: {
      root: path(ROOTS_DASHBOARD, '/hr/employees'),
      list: path(ROOTS_DASHBOARD, '/hr/employees/list/now'),
      formerList: path(ROOTS_DASHBOARD, '/hr/employees/list/former'),
    },
    salary: {
      root: path(ROOTS_DASHBOARD, '/hr/salary'),
      timeLog: path(ROOTS_DASHBOARD, '/hr/salary/timeLog'),
      generateSalary: path(ROOTS_DASHBOARD, '/hr/salary/generateSalary'),
    },
    rost: {
      manager: path(ROOTS_DASHBOARD, '/hr/rost/manager'),
    },
    leave: {
      manager: path(ROOTS_DASHBOARD, '/hr/leave/manager/all'),
    },
    report: {
      root: path(ROOTS_DASHBOARD, '/hr/report'),
    },
    training: {
      root: path(ROOTS_DASHBOARD, '/hr/training'),
    },
    expenses: {
      root: path(ROOTS_DASHBOARD, '/hr/expenses'),
      view: path(ROOTS_DASHBOARD, '/hr/expenses/view/hr'),
      category: path(ROOTS_DASHBOARD, '/hr/expenses/category/hr'),
    },
  },
  quality: {
    inspection: {
      root: path(ROOTS_DASHBOARD, '/quality/inspection'),
      type: path(ROOTS_DASHBOARD, '/quality/inspection/typeList'),
      templateList: path(ROOTS_DASHBOARD, '/quality/inspection/inspectionTemplateList'),
      examin: path(ROOTS_DASHBOARD, '/quality/inspection/inspectionExamin'),
    },
    expenses: {
      root: path(ROOTS_DASHBOARD, '/quality/expenses'),
      view: path(ROOTS_DASHBOARD, '/quality/expenses/view/quality'),
      category: path(ROOTS_DASHBOARD, '/quality/expenses/category/quality'),
    },
  },
  kitchen: {
    root: path(ROOTS_DASHBOARD, '/kitchen'),
    cookingStatus: path(ROOTS_DASHBOARD, '/kitchen/cooking'),
    cookingProcess: path(ROOTS_DASHBOARD, '/kitchen/cookingProcess'),
    store: path(ROOTS_DASHBOARD, '/kitchen/store'),
    display: path(ROOTS_DASHBOARD, '/kitchen/display'),
  },
  marketing: {
    root: path(ROOTS_DASHBOARD, '/marketing'),
    discounts: path(ROOTS_DASHBOARD, '/marketing/coupons/discount'),
    vouchers: path(ROOTS_DASHBOARD, '/marketing/coupons/voucher'),
    bulksms: path(ROOTS_DASHBOARD, '/marketing/bulksms'),
    expenses: {
      root: path(ROOTS_DASHBOARD, '/marketing/expenses'),
      view: path(ROOTS_DASHBOARD, '/marketing/expenses/view/marketing'),
      category: path(ROOTS_DASHBOARD, '/marketing/expenses/category/marketing'),
    },
  },
  procurement: {
    root: path(ROOTS_DASHBOARD, '/procurement'),
    assets: {
      root: path(ROOTS_DASHBOARD, '/procurement/assets'),
      list: path(ROOTS_DASHBOARD, '/procurement/assets/list'),
      categories: path(ROOTS_DASHBOARD, '/procurement/assets/categories'),
    },
    suppliers: {
      root: path(ROOTS_DASHBOARD, '/procurement/suppliers'),
      assetSupplier: path(ROOTS_DASHBOARD, '/procurement/suppliers/assetSupplier'),
      serviceSupplier: path(ROOTS_DASHBOARD, '/procurement/suppliers/serviceSupplier'),
    },
    marketingSupplier: path(ROOTS_DASHBOARD, '/suppliers/marketingSupplier'),
    otherSupplier: path(ROOTS_DASHBOARD, '/suppliers/otherSupplier'),
    expenses: {
      root: path(ROOTS_DASHBOARD, '/procurement/expenses'),
      view: path(ROOTS_DASHBOARD, '/procurement/expenses/view/procurement'),
      category: path(ROOTS_DASHBOARD, '/procurement/expenses/category/procurement'),
    },
  },
  administration: {
    root: path(ROOTS_DASHBOARD, '/administration'),
    otherSupplier: path(ROOTS_DASHBOARD, '/administration/suppliers/otherSupplier'),
    expenses: {
      root: path(ROOTS_DASHBOARD, '/administration/expenses'),
      view: path(ROOTS_DASHBOARD, '/administration/expenses/view/administration'),
      category: path(ROOTS_DASHBOARD, '/administration/expenses/category/administration'),
    },
  },
  finance: {
    root: path(ROOTS_DASHBOARD, '/finance'),
    cashflow: path(ROOTS_DASHBOARD, '/finance/cashflow'),
    controllerAccount: {
      root: path(ROOTS_DASHBOARD, '/finance/controllerAccount'),
      revenue: path(ROOTS_DASHBOARD, '/finance/controllerAccount/1'),
      costOfSale: path(ROOTS_DASHBOARD, '/finance/controllerAccount/2'),
      adminExpenses: path(ROOTS_DASHBOARD, '/finance/controllerAccount/3'),
      sellingDistributionAndMarkertingExpense: path(ROOTS_DASHBOARD, '/finance/controllerAccount/4'),
      financeExpense: path(ROOTS_DASHBOARD, '/finance/controllerAccount/5'),
      nonCurrentAssets: path(ROOTS_DASHBOARD, '/finance/controllerAccount/6'),
      currentAssets: path(ROOTS_DASHBOARD, '/finance/controllerAccount/7'),
      cashbook: path(ROOTS_DASHBOARD, '/finance/controllerAccount/8'),
      equityAndLiabilities: path(ROOTS_DASHBOARD, '/finance/controllerAccount/9'),
      tradePayable: path(ROOTS_DASHBOARD, '/finance/controllerAccount/10'),
    },
    grnPayments: {
      root: path(ROOTS_DASHBOARD, '/finance/grnPayments'),
      initiate: path(ROOTS_DASHBOARD, '/finance/grnPayments/initiate'),
      confirm: path(ROOTS_DASHBOARD, '/finance/grnPayments/confirm'),
      paid: path(ROOTS_DASHBOARD, '/finance/grnPayments/paid'),
      deleted: path(ROOTS_DASHBOARD, '/finance/grnPayments/deleted'),
      finalized: path(ROOTS_DASHBOARD, '/finance/grnPayments/finalized'),
    },
    saleDepositVerification: path(ROOTS_DASHBOARD, '/finance/saleDepositVerification'),
    globalCashbook: path(ROOTS_DASHBOARD, '/finance/globalCashbook'),
    cashflowTransfers: path(ROOTS_DASHBOARD, '/finance/cashflowTransfers'),
    setting: {
      root: path(ROOTS_DASHBOARD, '/finance/setting'),
      cashflowCategories: path(ROOTS_DASHBOARD, '/finance/setting/cashflowCategories'),
      cashFlowDocumentType: path(ROOTS_DASHBOARD, '/finance/setting/cashFlowDocumentType'),
      paymentTypes: path(ROOTS_DASHBOARD, '/finance/setting/paymentTypes'),
    },
    expenses: {
      root: path(ROOTS_DASHBOARD, '/finance/expenses'),
      view: path(ROOTS_DASHBOARD, '/finance/expenses/view/finance'),
      category: path(ROOTS_DASHBOARD, '/finance/expenses/category/finance'),
    },
  },
  setting: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    general: {
      root: path(ROOTS_DASHBOARD, '/settings/general'),
      locations: path(ROOTS_DASHBOARD, '/settings/general/locations'),
    },
    account: {
      root: path(ROOTS_DASHBOARD, '/settings/account'),
      ownAccount: path(ROOTS_DASHBOARD, '/settings/account/userAccount/own'),
    },
  },

  // Other line of business.
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings/locations'),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product/list'),
    products: path(ROOTS_DASHBOARD, '/product/list'),
    categories: path(ROOTS_DASHBOARD, '/product/categories'),
    tags: path(ROOTS_DASHBOARD, '/product/tags'),
    suppliers: path(ROOTS_DASHBOARD, '/product/suppliers'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
