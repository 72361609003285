import axios from 'axios';
// config
import { HOST_API } from '../config';

// Create axios instance
const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Reject the promise with the error response or a custom message
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
