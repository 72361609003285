import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Paper, Stack, SwipeableDrawer, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { getUsers } from '../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { addSupplierOrder, getEnterPrices } from '../../../redux/slices/purchaseRedux/purchaseRedux';
import { fCurrency } from '../../../utils/formatNumber';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { accessVerify, capitalize } from '../../../utils/common';

export default function AddToCart() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enteredPricing, setEnteredPricing] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [dataUpdated, setDataUpdated] = useState(false);

  const { data: enterPricesData, addData } = useSelector((state) => state.enterPrices);

  useEffect(() => {
    dispatch(getEnterPrices());
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    setEnteredPricing(
      enterPricesData?.map((value) => ({
        purchaseUnitQty: '',
        storeUnitQty: '',
        ...value,
      }))
    );
    setIsModelOpen(false);
  }, [enterPricesData]);

  const updateQuantity = (id, key, qty) => {
    setEnteredPricing((prevEnteredPricing) =>
      prevEnteredPricing.map((value) =>
        value.id === id
          ? {
              ...value,
              [key]: qty,
            }
          : value
      )
    );
  };

  const manageModel = (type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      if (type === 'add') {
        setIsModelOpen(true);
      }
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    setDataUpdated(true);
    try {
      dispatch(addSupplierOrder(cartData));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (addData?.data) {
      dispatch(getEnterPrices());
      setIsLoading(false);
      if (dataUpdated) {
        navigate(PATH_DASHBOARD.sales.purchaseNotes);
      }
    }
  }, [addData]);

  const getNeededProductList = () => {
    const productListData = enteredPricing?.filter((value) => value?.purchaseUnitQty && value?.storeUnitQty);

    const supplierList = Array.from(new Set(productListData.map((value) => value?.SupplierId)));

    return supplierList?.map((supplierId) => {
      const productList = productListData?.filter((product) => product?.SupplierId === supplierId);
      const supplierName = productList?.[0]?.SupplierName;
      const totalAmount = productList?.reduce((total, product) => total + (product?.price * product?.storeUnitQty || 0), 0);
      return {
        supplierId,
        supplierName,
        productList,
        totalAmount,
      };
    });
  };

  const columns = !enteredPricing?.[0]
    ? []
    : Object.keys(enteredPricing?.[0]).map((key) => {
        if (key === 'purchaseUnitQty') {
          return {
            accessorKey: key,
            header: capitalize(key),
            Cell: ({ cell, row }) => (
              <TextField
                type='number'
                label={`Purchase QTY`}
                value={row?.original[key] || ''}
                onChange={(event) => updateQuantity(row?.original.id, key, event.target.value)}
                onClick={(event) => {
                  event.target.select();
                }}
              />
            ),
          };
        } else if (key === 'storeUnitQty') {
          return {
            accessorKey: key,
            header: capitalize(key),
            Cell: ({ cell, row }) => (
              <TextField
                type='number'
                label={`Sto. QTY (${row?.original?.unitSymbol})`}
                value={row?.original[key] || ''}
                onChange={(event) => updateQuantity(row?.original.id, key, event.target.value)}
                onClick={(event) => {
                  event.target.select();
                }}
              />
            ),
          };
        } else if (key === 'price') {
          return {
            accessorKey: key,
            header: 'Price Per Unit',
            Cell: ({ cell }) => fCurrency(cell.getValue()),
          };
        }
        return {
          accessorKey: key,
          header: capitalize(key),
        };
      });

  const [rowSelection, setRowSelection] = useState(false);

  return (
    <Page title="Add to Cart">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Add to Cart"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, {
            name: 'Purchasing',
            href: PATH_DASHBOARD.purchasing.root,
          }, { name: 'Add to Cart' }]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                const cartData = getNeededProductList();
                if (cartData?.length > 0) {
                  setCartData(cartData);
                  manageModel('add');
                } else {
                  toast.warning('Please enter purchase unit qty and store unit qty for at least one product!');
                }
              }}
            >
              Generate Purchase Notes
            </Button>
          }
        />
        {accessVerify('ADD_TO_CART_VIEW') && (
          <DataGridTable name={'Add To Cart'} data={enteredPricing} column={columns} isLoading={false} rowSelection={rowSelection} setRowSelection={setRowSelection} enableRowActions={false} />
        )}
        <SwipeableDrawer
          anchor='top'
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>Generate Purchase Notes</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              {cartData?.map((value, index) => (
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Card sx={{ p: 3 }}>
                    <h3>
                      Order For {value?.supplierName} - ({fCurrency(value?.totalAmount)})
                    </h3>
                    <hr />
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Product Name</TableCell>
                            <TableCell>Price Per Unit</TableCell>
                            <TableCell>Purchase Unit Qty</TableCell>
                            <TableCell>Store Unit Qty</TableCell>
                            <TableCell>Total</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {value?.productList?.map((product) => (
                            <TableRow key={`${value?.supplierName}-${product?.FoodName}-${product?.id}`}>
                              <TableCell>{product?.id}</TableCell>
                              <TableCell>{product?.FoodName}</TableCell>
                              <TableCell>{fCurrency(product?.price)}</TableCell>
                              <TableCell>{product?.purchaseUnitQty}</TableCell>
                              <TableCell>{product?.storeUnitQty}</TableCell>
                              <TableCell>{fCurrency(product?.price * product?.storeUnitQty)}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                </Stack>
              ))}

              <Card sx={{ p: 3 }}>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <LoadingButton onClick={onSubmit} variant='contained' size='large' style={{ width: '100%' }} loading={isLoading}>
                    Verify & Create Purchase Notes
                  </LoadingButton>
                  <Button color='info' variant='outlined' size='large' style={{ width: '100%' }} onClick={() => manageModel('add')}>
                    Close
                  </Button>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
