import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, IconButton, SwipeableDrawer, Tooltip } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { fCurrency } from '../../../utils/formatNumber';
import {
  getPurchaseNotes,
  updatePurchaseNotes,
  updatePurchaseNotesPaymentNote,
  uploadPurchaseNotesDocuments,
} from '../../../redux/slices/purchaseRedux/purchaseNotesRedux';
import InvoicePDF from '../../../sections/@dashboard/invoice/details/InvoicePDF';
import { pdf } from '@react-pdf/renderer';
import { BASE_URL, COMPANY_DATA_FOR_PDF } from '../../../config';
import {
  accessVerify,
  capitalize,
  handleNumber,
  removeUnwantedStockBarCodeList,
  safeJSONParse,
  utcMoment,
} from '../../../utils/common';
import PermissionRequired from '../../errorPages/permissionRequired';
import { EditNote } from '@mui/icons-material';
import { CloudUploadRounded, PictureAsPdf } from '@material-ui/icons';
import CashFlowImageUploader from '../finance/cashflow/components/cashflowImageUploader';
import ImageViewer from 'react-simple-image-viewer';
import { imageUpload } from '../../../inteceptor';
import Iconify from '../../../components/Iconify';

export default function PurchaseNotes() {
  const { themeStretch } = useSettings();
  const [enteredPricing, setEnteredPricing] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [selectedDataObj, setSelectedDataObj] = useState({ linkedDocuments: [] });


  const { loggedInUser } = useSelector((state) => state?.user);
  const { data, addData } = useSelector((state) => state?.purchaseNotes);
  const { selectedLocation } = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(getPurchaseNotes());
  }, []);

  useEffect(() => {
    setEnteredPricing(data?.map(value=> {
      return {
        day: utcMoment(value?.date).format('YYYY-MM-DD'),
        time: utcMoment(value?.date).format('hh:mm A'),
        ...value
      }
    }));
    setEnteredPricing(data?.map(value=> {
      return {
        ...value,
        linkedDocuments: value?.linkedDocuments ? safeJSONParse(value?.linkedDocuments)?.map(value=> value?.url) : []
      }
    }));
  }, [data]);

  useEffect(() => {
    if (addData?.data) {
      dispatch(getPurchaseNotes());
    }
  }, [addData]);

  const getItemsForInvoice = (rowData) => {
    if (rowData) {
      const parsedRowData = safeJSONParse(rowData);
      return parsedRowData?.map((value, index) => {
        const jsonData = safeJSONParse(value?.jsonData);
        const pricePerSupplierUnit = (jsonData?.price / jsonData?.purchaseUnitQty) * jsonData?.storeUnitQty;
        return {
          id: index,
          title: jsonData?.FoodName,
          foodId: value?.foodId,
          description: `Final Price is checked on ${jsonData?.formatted_dateTime}.`,
          quantity: jsonData?.purchaseUnitQty,
          storeUnitQty: jsonData?.storeUnitQty + ' ' + jsonData?.unitSymbol,
          price: pricePerSupplierUnit,
        };
      });
    }
  };
  useEffect(() => {
    setDocumentGallery(selectedDataObj?.linkedDocuments);
  }, [selectedDataObj]);

  const handleDownload = async (row) => {
    const pdfBlob = await pdf(
      <InvoicePDF
        invoice={{
          invoiceNumber: `INV-${row?.original?.id}`,
          createDate: utcMoment(row?.original?.date).format('DD MMMM yyyy').toString(),
          dueDate: utcMoment(row?.original?.date).add(row?.original?.supplierNumberOfDaysForPayment || 30, 'days').format('DD MMMM yyyy').toString(),
          status: row?.original?.status,
          discount: 0,
          taxes: 0,
          paidAmount: handleNumber(row?.original?.paidamount),
          totalPrice: handleNumber(row?.original?.totalamount) - handleNumber(row?.original?.paidamount),
          subTotalPrice: handleNumber(row?.original?.totalamount),
          topic: 'Purchase Order',
          invoiceFrom: {
            name: COMPANY_DATA_FOR_PDF?.name,
            address: selectedLocation?.address,
            phone: selectedLocation?.contactNumber,
          },
          invoiceTo: {
            name: row?.original?.supplierName,
            address: row?.original?.supplierAddress,
            phone: row?.original?.supplierCompanyPhone,
          },
          items: getItemsForInvoice(row?.original?.supplierorder_foodsupplierpricing),
          stockCodes: removeUnwantedStockBarCodeList(safeJSONParse(row?.original?.stockCodes)) || [],
          linkedDocuments: row?.original?.linkedDocuments?.map(value=> value?.replace("https://storage.googleapis.com/bubblemania/", `${BASE_URL}imageViewer?path=`)) || []
        }}
      />
    ).toBlob();

    const pdfUrl = URL.createObjectURL(pdfBlob);
    const downloadLink = document.createElement('a');
    downloadLink.href = pdfUrl;
    downloadLink.download = `Invoice-${row?.original?.id}.pdf`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(pdfUrl);
  };

  const columns = !enteredPricing?.[0]
    ? []
    : Object.keys(enteredPricing?.[0])
        .map((value) => {
          if (
            value === 'date' ||
            value === 'supplierAddress' ||
            value === 'estimatedreceivingdate' ||
            value === 'paymentdate' ||
            value === 'paymentmethod' ||
            value === 'supplierCode' ||
            value === 'supplierCompanyPhone' ||
            value === 'paymentMethod' ||
            value === 'paymentDate' ||
            value === 'estimatedReceivingDate' ||
            value === 'orderedById' ||
            value === 'supplierorder_foodsupplierpricing'
          ) {
            return null;
          } else if (value === 'supplierId') {
            return {
              accessorKey: 'supplierId',
              header: 'Invoice Id',
              Cell: ({ row }) => `INV-${row?.original?.id}`,
            };
          } else if (value === 'totalamount') {
            return {
              accessorKey: 'totalamount',
              header: 'Total Amount',
              Cell: ({ row }) => fCurrency(row?.original?.totalamount),
            };
          } else if (value === 'paidamount') {
            return {
              accessorKey: 'paidamount',
              header: 'Paid Amount',
              Cell: ({ row }) => fCurrency(row?.original?.paidamount),
            };
          } else if (value === 'paymentforreceivings') {
            return {
              accessorKey: 'paymentforreceivings',
              header: 'Payment Amount For Receivings',
              Cell: ({ row }) => fCurrency(row?.original?.paymentforreceivings),
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        .filter((value) => value);

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `Finance/PurchaseNote_Invoice/Loc_${selectedLocation?.id}/User_${loggedInUser?.id}`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });
        if (data?.status) {
          setSelectedDataObj((prevState) => ({
            ...prevState,
            linkedDocuments: [...prevState.linkedDocuments, data?.url],
          }));
          dispatch(uploadPurchaseNotesDocuments({ supplierorderId: selectedDataObj?.id ,url: data?.url }));
        }
      } catch (error) {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };


  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);


  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Page title='Purchase Notes'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Purchase Notes'
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Purchasing',
              href: PATH_DASHBOARD.purchasing.root,
            },
            { name: 'Purchase Notes' },
          ]}
          action={[]}
        />

        {accessVerify('PURCHASE_NOTE_VIEW') ? (
          <DataGridTable
            name={'Purchase Notes'}
            data={enteredPricing}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowActions={true}
            renderRowActionItems={(row, closeMenu) => [
              <div style={{ minWidth: '100px' }}>
                <Tooltip title='Add Payment Note'>
                  <IconButton color="secondary" onClick={() => {
                    const paymentNote = prompt("Enter Purchase Note:", row.original?.paymentNote || '');
                    if(paymentNote) {
                      dispatch(updatePurchaseNotesPaymentNote({id: row.original?.id, paymentNote}));
                    }
                  }}>
                    <EditNote />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Download PDF'>
                  <IconButton color="error" onClick={() => {
                    handleDownload(row);
                  }}>
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Upload Invoice'>
                    <IconButton color={row.original?.status === 'DRAFT' ? 'warning':'success'} onClick={() => {
                      setIsModelOpen(true);
                      setSelectedDataObj(row?.original);
                    }}>
                      <CloudUploadRounded />
                    </IconButton>
                </Tooltip>
              </div>,
            ]}
          />
        ) : (
          <PermissionRequired />
        )}
        <SwipeableDrawer
          anchor={'bottom'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '95%',
              height: '95%',
              marginLeft: '2.5%',
              borderRadius: '10px',
            },
          }}
        >
          <CashFlowImageUploader
            files={files}
            setFiles={setFiles}
            handleImageUpload={handleImageUpload}
            documentGallery={documentGallery}
            openImageViewer={openImageViewer}
            isUploading={isUploading}
          />
          {isViewerOpen && <ImageViewer src={documentGallery} currentIndex={currentImage} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />}

          {(selectedDataObj?.linkedDocuments?.length > 0 && selectedDataObj?.status === 'DRAFT') && <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              setSelectedDataObj(prevState => {
                return {...prevState,
                status: 'Updated'}
              })
              dispatch(
                updatePurchaseNotes({
                  id: selectedDataObj?.id,
                  status: 'PROCESSING',
                })
              );
            }}
          >
            Upload And continue process
          </Button>}
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
