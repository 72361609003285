import React, { useEffect, useState } from 'react';
import { Card, Container, Grid, Stack, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { getStocks } from '../../../redux/slices/stockManagementRedux/stockRedux';
import {
  updateLatestAvailableTime,
} from '../../../redux/slices/stockManagementRedux/stockTransferRedux';
import useSound from 'use-sound';
import errorFx from '../../../assets/sounds/error.wav';
import successFx from '../../../assets/sounds/success.wav';

const StockAvailabilityChecker = () => {
  const { themeStretch } = useSettings();
  const [data, setData] = useState();
  const { selectedLocation } = useSelector((state) => state.location);

  const [playErrorSound] = useSound(errorFx, {
    volume: 0.8,
    playbackRate: 1.2,
  });

  const [playSuccessSound] = useSound(successFx, {
    volume: 0.8,
    playbackRate: 1.2,
  });

  const { data: stockData } = useSelector((state) => state?.stock);
  const { addData } = useSelector((state) => state?.stockTransfer);

  useEffect(() => {
    if (data?.barcode?.length > 0) {
      dispatch(getStocks(-1, -1, -1, data?.barcode));
    }
  }, [data?.barcode]);
  useEffect(() => {
    if (addData?.data?.affectedRows > 0) {
      playSuccessSound();
    } else {
      playErrorSound();
    }
  }, [addData]);

  return (
    <Page title='Availability Checker'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading='Availability Checker'
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.transfer.sender }, { name: 'Availability Checker' }]}
          action={[]}
        />
        <Card>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Stack spacing={3} sx={{ p: 3 }}>
                <Card
                  sx={{
                    p: 3,
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eee',
                    },
                  }}
                >
                  <TextField
                    type='text'
                    label={`Enter Barcode`}
                    sx={{ width: '100%' }}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        event.target.select();
                        dispatch(
                          updateLatestAvailableTime({
                            code: event.target.value,
                            locationId: selectedLocation?.id,
                            locationName: selectedLocation?.name,
                          })
                        );
                        setData(event.target.value);
                      }
                    }}
                  />
                  <br />
                  <br />

                  <h2 style={{ textAlign: 'center' }}>Latest Updated Code Is</h2>
                  <h1 style={{ textAlign: 'center' }}>{data}</h1>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default StockAvailabilityChecker;
