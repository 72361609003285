import React, { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Card, Container, Grid, IconButton, List, ListItem, Stack, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { dispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import useSettings from '../../../hooks/useSettings';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../components/table/DataGridTable';
import { LoadingButton } from '@mui/lab';
import { alpha, useTheme } from '@mui/material/styles';
import { addAssets, getAssets, updateAssets } from '../../../redux/slices/assetsRedux/assetsRedux';
import { getUsers } from '../../../redux/slices/hrRedux/UserManagementRedux/userManagementRedux';
import { toast } from 'react-toastify';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../utils/common';
import { fCurrency } from '../../../utils/formatNumber';
import { getAssetCategories } from '../../../redux/slices/assetsRedux/assetCategoriesRedux';
import { getSuppliers } from '../../../redux/slices/stockManagementRedux/supplierRedux';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import UploadMultiFile from '../../../components/upload/UploadMultiFile';
import { AnimatePresence, m } from 'framer-motion';
import Image from '../../../components/Image';
import ImageViewer from 'react-simple-image-viewer';
import { imageUpload } from '../../../inteceptor';
import PermissionRequired from '../../errorPages/permissionRequired';

export default function AssetsList() {
  const theme = useTheme();
  const dataModel = {
    id: '',
    name: '',
    description: '',
    totalValue: '',
    depreciatedAmountPerDay: '',
    locationId: '',
    supplierId: '',
    numberOfServicePeriodDays: '',
    assetCategoriesId: '',
    responsibleUserId: '',
    addedUser: '',
    assetsDocumentsData: [],
  };
  const [selectedDataObj, setSelectedDataObj] = useState(dataModel);
  const { themeStretch } = useSettings();
  const [isAdd, setIsAdd] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [assetsList, setAssetsList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [files, setFiles] = useState([]);
  const [documentGallery, setDocumentGallery] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const { data, addData } = useSelector((state) => state.assets);

  let allLocations;
  let selectedLocation;
  ({ data: allLocations, selectedLocation } = useSelector((state) => state.location));

  let userListData;
  ({ data: userListData } = useSelector((state) => state.user));
  let assetCategoryList;
  ({ data: assetCategoryList } = useSelector((state) => state.assetCategories));
  let suppliersList;
  ({ data: suppliersList } = useSelector((state) => state.supplier));

  useEffect(() => {
    dispatch(getSuppliers('assetSupplier'));
    dispatch(getUsers());
    dispatch(getAssetCategories());
  }, []);

  useEffect(() => {
    dispatch(getAssets(selectedLocation?.id));
  }, [selectedLocation]);

  useEffect(() => {
    if (userListData) {
      setUserList(userListData);
    }
  }, [userListData]);

  useEffect(() => {
    setAssetsList(
      data?.map((value) => {
        return {
          ...value,
          assetsDocumentsData: safeJSONParse(value?.assetsDocumentsData),
          locationData: safeJSONParse(value?.locationData),
          userData: safeJSONParse(value?.userData),
          logData: safeJSONParse(value?.logData),
        };
      })
    );
    setIsModelOpen(false);
  }, [data]);

  const manageModel = (modelData, type) => {
    if (isModelOpen) {
      setIsModelOpen(false);
    } else {
      setIsModelOpen(true);
      setIsAdd(type === 'add');
      if (type === 'add') {
        setDocumentGallery([]);
        setSelectedDataObj(dataModel);
      } else {
        setSelectedDataObj(modelData);
      }
    }
  };

  useEffect(() => {
    setDocumentGallery(selectedDataObj?.assetsDocumentsData?.map((value) => value?.url));
  }, [selectedDataObj]);

  useEffect(() => {
    if (addData?.data) {
      dispatch(getAssets(selectedLocation?.id));
    }
  }, [addData]);

  const updateEditingData = (key, value) => {
    setSelectedDataObj({
      ...selectedDataObj,
      [key]: value,
    });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    for (const file of files) {
      try {
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: true,
              };
            }
            return fileValue;
          });
        });

        const data = await uploadFiles(file);

        setFiles((value) => {
          return value.filter((fileValue, index) => file?.id !== fileValue?.id);
        });

        if (data?.status) {
          setSelectedDataObj((prevState) => ({
            ...prevState,
            assetsDocumentsData: [...prevState.assetsDocumentsData, { name: file?.name, url: data?.url }],
          }));
        }
      } catch (error) {
        console.error(error);
        setFiles((value) => {
          return value.map((fileValue, index) => {
            if (file?.id === fileValue?.id) {
              return {
                ...fileValue,
                isUploading: false,
              };
            }
            return fileValue;
          });
        });
      }
    }
    setIsUploading(false);
  };

  const columns = !assetsList?.[0]
    ? []
    : Object.keys(assetsList?.[0])
        .map((value) => {
          if (['locationData', 'userData', 'categoryData', 'supplierData', 'logData', 'assetsDocumentsData'].includes(value)) {
            return {};
          } else if (value === 'locationId') {
            return {
              accessorKey: 'locationId',
              header: 'Current Location',
              Cell: ({ cell, row }) => {
                return allLocations?.find((value) => value.id === row?.original?.locationId)?.name || 'Location Not Found';
              },
            };
          } else if (value === 'responsibleUserId') {
            return {
              accessorKey: 'responsibleUserId',
              header: 'Responsible Person',
              Cell: ({ cell, row }) => {
                return userList?.find((value) => value.id === row?.original?.responsibleUserId)?.name || 'Responsible User Not Found';
              },
            };
          } else if (value === 'addedUser') {
            return {
              accessorKey: 'addedUser',
              header: 'Added User',
              Cell: ({ cell, row }) => {
                return userList?.find((value) => value.id === row?.original?.addedUser)?.name || 'User Not Found';
              },
            };
          } else if (value === 'supplierId') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return safeJSONParse(row?.original?.supplierData)?.name;
              },
            };
          } else if (value === 'assetCategoriesId') {
            return {
              accessorKey: value,
              header: capitalize(value),
              Cell: ({ cell, row }) => {
                return safeJSONParse(row?.original?.categoryData)?.name;
              },
            };
          } else if (value === 'totalValue') {
            return {
              accessorKey: 'totalValue',
              header: 'Total Value',
              Cell: ({ cell, row }) => {
                return fCurrency(row?.original?.totalValue);
              },
            };
          } else if (value === 'depreciatedAmountPerDay') {
            return {
              accessorKey: 'totalValue',
              header: 'Depreciated Amount Per Day',
              Cell: ({ cell, row }) => {
                return fCurrency(row?.original?.depreciatedAmountPerDay);
              },
            };
          } else {
            return {
              accessorKey: value,
              header: capitalize(value),
            };
          }
        })
        ?.filter((value) => value?.accessorKey);

  const [rowSelection, setRowSelection] = useState(false);

  const handleSubmit = () => {
    if (selectedDataObj.name.length === 0) {
      toast.error('Asset name is required!');
    } else if (selectedDataObj.description.length === 0) {
      toast.error('Asset description is required!');
    } else if (selectedDataObj.totalValue === 0) {
      toast.error('Asset total value is required!');
    } else if (selectedDataObj.depreciatedAmountPerDay.length === 0) {
      toast.error('Depreciated amount per day is required!');
    } else if (selectedDataObj.responsibleUserId === '') {
      toast.error('Responsible user is required!');
    } else if (selectedDataObj.locationId === '') {
      toast.error('Location is required!');
    } else if (selectedDataObj.supplierId === '') {
      toast.error('Supplier is required!');
    } else if (selectedDataObj.numberOfServicePeriodDays === '') {
      toast.error('Number of service period days are required!');
    } else if (selectedDataObj.assetCategoriesId === '') {
      toast.error('Category required!');
    } else {
      dispatch(isAdd ? addAssets(selectedDataObj) : updateAssets(selectedDataObj));
    }
  };

  const uploadFiles = (file) => {
    return new Promise((resolve, reject) => {
      imageUpload(
        file,
        `AssetsDocuments`,
        (data) => {
          if (data?.status) {
            resolve(data);
          } else {
            reject(new Error('Upload failed'));
          }
        },
        (errorMessage) => {
          console.error('Upload failed:', errorMessage);
          reject(new Error(errorMessage));
        }
      );
    });
  };

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <Page title={`Assets List`}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`Assets List`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Asset',
              href: '',
            },
            { name: `Assets List` },
          ]}
          action={
            <Button
              variant='contained'
              startIcon={<Iconify icon='eva:plus-fill' />}
              onClick={() => {
                manageModel(dataModel, 'add');
              }}
            >
              New Asset
            </Button>
          }
        />

        {accessVerify('ASSETS_LIST_VIEW') ? (
          <DataGridTable
            name={'Assets List'}
            data={assetsList}
            column={columns}
            onRowClick={(row) => {
              manageModel(row?.original, 'edit');
            }}
            isRowClickable={true}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            enableRowSelection={false}
            enableRowActions={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}

        <SwipeableDrawer
          anchor={'top'}
          open={isModelOpen}
          onOpen={() => setIsModelOpen(true)}
          onClose={() => setIsModelOpen(false)}
          PaperProps={{
            sx: {
              width: '90%',
              height: '90%',
              marginLeft: '5%',
              marginTop: '3%',
              borderRadius: '10px',
            },
          }}
        >
          <Box sx={{ p: 2 }}>
            <h1>{isAdd ? 'Add' : 'Edit'} Asset</h1>
          </Box>
          <Grid container spacing={1}>
            <Grid item xs={12} md={8}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Asset Information
                  </Typography>
                  <Stack spacing={3}>
                    <TextField fullWidth label='Name' value={selectedDataObj?.name} onChange={(e) => updateEditingData('name', e.target.value)} />
                    <TextField fullWidth label='Description' value={selectedDataObj?.description} onChange={(e) => updateEditingData('description', e.target.value)} />
                    <TextField fullWidth type={'number'} label='Asset Total Value' value={selectedDataObj?.totalValue} onChange={(e) => updateEditingData('totalValue', e.target.value)} />
                    <TextField
                      fullWidth
                      type={'number'}
                      label='Depreciation Amount Per Day'
                      value={selectedDataObj?.depreciatedAmountPerDay}
                      onChange={(e) => updateEditingData('depreciatedAmountPerDay', e.target.value)}
                    />
                    <TextField
                      fullWidth
                      type={'number'}
                      label='Service Period (Days)'
                      value={selectedDataObj?.numberOfServicePeriodDays}
                      onChange={(e) => updateEditingData('numberOfServicePeriodDays', e.target.value)}
                    />

                    <Autocomplete
                      onChange={(event, newValue) => {
                        const userInfo = suppliersList.find((value) => value?.id === newValue?.value);
                        updateEditingData('supplierId', userInfo?.id);
                      }}
                      options={suppliersList?.map((value) => ({
                        label: `${value.name}`,
                        value: value.id,
                      }))}
                      value={{
                        label: `${suppliersList?.find((value) => value.id === selectedDataObj?.supplierId)?.name || 'Assets/Services Supplier Not Found'}`,
                        value: selectedDataObj?.supplierId,
                      }}
                      renderInput={(params) => <TextField label='Suppliers' {...params} />}
                    />

                    <Autocomplete
                      onChange={(event, newValue) => {
                        const userInfo = assetCategoryList.find((value) => value?.id === newValue?.value);
                        updateEditingData('assetCategoriesId', userInfo?.id);
                      }}
                      options={assetCategoryList?.map((value) => ({
                        label: `${value.name}`,
                        value: value.id,
                      }))}
                      value={{
                        label: `${assetCategoryList?.find((value) => value.id === selectedDataObj?.assetCategoriesId)?.name || 'Category Not Found'}`,
                        value: selectedDataObj?.assetCategoriesId,
                      }}
                      renderInput={(params) => <TextField label='Category' {...params} />}
                    />

                    <Autocomplete
                      onChange={(event, newValue) => {
                        const userInfo = userList.find((value) => value?.id === newValue?.value);
                        updateEditingData('responsibleUserId', userInfo?.id);
                      }}
                      options={userList?.map((value) => ({
                        label: `${value.name}`,
                        value: value.id,
                      }))}
                      value={{
                        label: `${userList?.find((value) => value.id === selectedDataObj?.responsibleUserId)?.name || 'Responsible Person Not Found'}`,
                        value: selectedDataObj?.responsibleUserId,
                      }}
                      renderInput={(params) => <TextField label='Responsible Person' {...params} />}
                    />

                    <Autocomplete
                      onChange={(event, newValue) => {
                        const locationInfo = allLocations.find((value) => value?.id === newValue?.value);
                        updateEditingData('locationId', locationInfo?.id);
                      }}
                      options={allLocations?.map((value) => ({
                        label: `${value.name}`,
                        value: value.id,
                      }))}
                      value={{
                        label: `${allLocations?.find((value) => value.id === selectedDataObj?.locationId)?.name || 'Location Not Found'}`,
                        value: selectedDataObj?.locationId,
                      }}
                      renderInput={(params) => <TextField label='Location' {...params} />}
                    />
                  </Stack>
                </Card>
              </Stack>
            </Grid>

            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{ p: 1 }}>
                <Card sx={{ p: 3 }}>
                  <Typography variant='subtitle1' padding='10px 0'>
                    Related Documents
                  </Typography>
                  <UploadMultiFile
                    error={false}
                    showPreview={true}
                    files={files}
                    onRemove={(removedFile) => {
                      setFiles(files.filter((file) => file !== removedFile));
                    }}
                    onRemoveAll={() => {
                      setFiles([]);
                    }}
                    helperText={''}
                    onDrop={(uploadedFiles) => {
                      setFiles((value) => [
                        ...value,
                        ...uploadedFiles.map((file, index) =>
                          Object.assign(file, {
                            id: index,
                            preview: URL.createObjectURL(file),
                            isUploading: false,
                          })
                        ),
                      ]);
                    }}
                    onUpload={() => {
                      handleImageUpload();
                    }}
                    isUploading={isUploading}
                  />

                  {documentGallery?.filter((value) => value)?.length > 0 && (
                    <Typography variant='subtitle1' padding='10px 0'>
                      Uploaded Images
                    </Typography>
                  )}
                  <List disablePadding sx={{ my: 3 }}>
                    <AnimatePresence>
                      {documentGallery
                        ?.filter((value) => value)
                        ?.map((src, index) => (
                          <>
                            <ListItem
                              key={`${src}${index}`}
                              component={m.div}
                              onClick={() => {
                                openImageViewer(index);
                              }}
                              sx={{
                                p: 0,
                                m: 0.5,
                                width: 80,
                                height: 80,
                                borderRadius: 1.25,
                                overflow: 'hidden',
                                position: 'relative',
                                cursor: 'pointer',
                                display: 'inline-flex',
                                border: (theme) => `solid 1px ${theme.palette.divider}`,
                              }}
                            >
                              <Image alt='preview' src={src} ratio='1/1' />
                              <IconButton
                                size='small'
                                sx={{
                                  top: 6,
                                  p: '2px',
                                  right: 6,
                                  position: 'absolute',
                                  color: 'common.white',
                                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                  '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                  },
                                }}
                              ></IconButton>
                            </ListItem>
                          </>
                        ))}
                    </AnimatePresence>
                  </List>
                </Card>
                <Card sx={{ p: 3 }}>
                  <Stack spacing={3} sx={{ p: 3 }}>
                    <LoadingButton type='submit' variant='contained' size='large' style={{ width: '100%' }} onClick={handleSubmit}>
                      {isAdd ? 'Add Asset' : 'Save Changes'}
                    </LoadingButton>
                    <Button
                      color='info'
                      variant='outlined'
                      size='large'
                      style={{ width: '100%' }}
                      onClick={() => {
                        manageModel(dataModel, 'add');
                      }}
                    >
                      Close
                    </Button>
                  </Stack>
                </Card>
                {selectedDataObj?.logData?.length > 0 && (
                  <Card>
                    <Typography variant='subtitle1' sx={{ p: 3 }}>
                      History Information
                    </Typography>
                    <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                      <Timeline position='alternate'>
                        {selectedDataObj?.logData?.map((value) => (
                          <TimelineItem key={value.id}>
                            <TimelineOppositeContent sx={{ m: 'auto 0' }} align='right' variant='body2' color='text.secondary'>
                              {utcMoment(value?.dateTime).format('YYYY-MM-DD HH:mm:ss')}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                              <TimelineConnector />
                              <TimelineDot>
                                <Iconify icon='eva:checkmark-circle-2-fill' />
                              </TimelineDot>
                              <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                              <Typography variant='body1' component='span'>
                                <b>{value?.title}</b>
                              </Typography>
                              <Typography>{value?.description}</Typography>
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    </div>
                  </Card>
                )}
              </Stack>
            </Grid>
            {isViewerOpen && <ImageViewer src={documentGallery} currentIndex={currentImage} disableScroll={false} closeOnClickOutside={true} onClose={closeImageViewer} />}
          </Grid>
        </SwipeableDrawer>
      </Container>
    </Page>
  );
}
