import React, { useEffect, useState } from 'react';
import { Autocomplete, Card, Container, Grid, TextField } from '@mui/material';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { accessVerify, capitalize, utcMoment } from '../../../../utils/common';
import { getCashFlowLedger, getCashFlowMainCategories, getCashFlowSubCategories} from '../../../../redux/slices/financeRedux/cashFlowRedux';
import PermissionRequired from '../../../errorPages/permissionRequired';
import { getPaymentTypes } from '../../../../redux/slices/financeRedux/paymentTypeRedux';
import { fCurrency } from '../../../../utils/formatNumber';
import { MobileDateRangePicker } from '@mui/lab';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { getAllSuppliers } from '../../../../redux/slices/stockManagementRedux/supplierRedux';

export default function ControlAccounts() {
  const { paramMainCategoryId } = useParams();
  const { themeStretch } = useSettings();

  let tempPaymentTypeList;
  ({ data: tempPaymentTypeList } = useSelector((state) => state.paymentTypes));
  let suppliersList;
  ({ allData: suppliersList } = useSelector((state) => state.supplier));
  let locationList;
  let selectedLocation;
  ({ data: locationList, selectedLocation } = useSelector((state) => state.location));
  const { data, cashFlowMainCategories, cashFlowSubCategories } = useSelector((state) => state.cashFlow);

  const [selectedMainCategoryId, setSelectedMainCategoryId] = useState(null);
  const [title, setTitle] = useState('Ledger');
  const [cashFlowList, setCashFlowList] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [selectedSupplierId, setSelectedSupplierId] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [openPicker, setOpenPicker] = useState(false);


  useEffect(() => {
    dispatch(getAllSuppliers())
    dispatch(getPaymentTypes());
    dispatch(getCashFlowMainCategories());
    dispatch(getCashFlowSubCategories());
  }, []);

  useEffect(() => {
    dispatch(getCashFlowLedger(selectedLocation?.id, paramMainCategoryId));
  }, [selectedLocation, paramMainCategoryId]);

  useEffect(() => {
    const mainAdministrationData = cashFlowMainCategories?.find(value=> value?.id === Number(selectedMainCategoryId));
    if(mainAdministrationData?.name){
      setTitle(`${mainAdministrationData?.name} Ledger`);
      setSubCategory(cashFlowSubCategories?.filter(value=> value?.mainCategoryId === Number(selectedMainCategoryId)))
    } else {
      setTitle(`Ledger`);
      setSubCategory(null);
    }
  }, [cashFlowMainCategories, cashFlowSubCategories, selectedMainCategoryId]);

  useEffect(() => {
    setSelectedMainCategoryId(Number(paramMainCategoryId));
  }, [paramMainCategoryId]);

  useEffect(() => {
    setPaymentTypeList(tempPaymentTypeList);
  }, [tempPaymentTypeList]);

  useEffect(() => {
    if (!data) return;

    let startingAmount = 0;

    const filteredData = data
      .filter((value) => {
        const paymentDate = moment(moment(value?.paymentDate).format('YYYY-MM-DD').toString()).startOf('day');
        const [startDate, endDate] = dateRange.map((date) =>
          date ? utcMoment(date).utc().startOf('day') : null
        );

        const isSelectedSupplier = selectedSupplierId
          ? value?.supplierId === selectedSupplierId
          : true;
        const isInRange = startDate && endDate
          ? paymentDate.isBetween(startDate, endDate, null, '[]')
          : true;


        const matchesSubCategory = selectedSubCategoryId
          ? (value?.creditLedgerId === selectedSubCategoryId) || (value?.debitLedgerId === selectedSubCategoryId)
          : true;

        return isInRange && matchesSubCategory && isSelectedSupplier;
      })
      .sort((a, b) => (a?.id || 0) - (b?.id || 0));

    const getProperLedgerName = (isCredit, value) => {
      let ledgerName = !isCredit ? value?.creditLedgerName :  value?.debitLedgerName;

      if(ledgerName === 'Trade Payables - Control Account' && value?.supplierName){
        ledgerName = `Trade Payables - (${value?.supplierName})`
      }
      return ledgerName;
    }

    const updatedCashflowList = filteredData.map((value) => {
      const isCredit = Number(value?.creditMainCategoryId) === Number(paramMainCategoryId);
      const isIncomeCategory = [1, 9, 10].includes(Number(paramMainCategoryId));
      const amount = value?.amount || 0;
      startingAmount += (isIncomeCategory !== !isCredit) ? amount : -amount;

      return {
        id: value?.id,
        date: utcMoment(value?.datetime).format('DD-MMMM-YYYY'),
        description: value?.description,
        mainCategory: !isCredit ? value?.creditMainCategoryName :  value?.debitMainCategoryName,
        ledger: getProperLedgerName(isCredit ,value),
        outlet: !isCredit ? value?.creditLocationName : value?.debitLocationName,
        dr: !isCredit ? fCurrency(value?.amount) : fCurrency(0),
        cr: isCredit ? fCurrency(value?.amount) : fCurrency(0),
        balance: fCurrency(startingAmount),
        userName: value?.userName
      };
    }).sort((a, b) => (b?.id || 0) - (a?.id || 0)).filter((value) => {
      const paymentDate = moment(moment(value?.paymentDate).format('YYYY-MM-DD').toString()).startOf('day');
      const [startDate, endDate] = dateRange.map((date) =>
        date ? utcMoment(date).utc().startOf('day') : null
      );

      return startDate && endDate
        ? paymentDate.isBetween(startDate, endDate, null, '[]')
        : true;
    });

    setCashFlowList(updatedCashflowList);
  }, [data, paymentTypeList, locationList, dateRange, selectedMainCategoryId, selectedSubCategoryId, selectedLocation, selectedSupplierId]);

  const columns = !cashFlowList?.[0]
    ? []
    : Object.keys(cashFlowList?.[0])
      .map((value) => {
        return {
          accessorKey: value,
          header: capitalize(value),
        };
      })
      ?.filter((value) => value?.accessorKey);

  const formatDateRangeDisplay = (range) => range[0] && range[1] ? `${moment(range[0]).format('DD-MMMM-YYYY')} - ${moment(range[1]).format('DD-MMMM-YYYY')}` : 'Select Date Range';

  return (
    <Page title={title}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={title}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Finance', href: '' },
            { name: title },
          ]}
          action={<></>}
        />
        <Card style={{ padding: '16px', marginBottom: '24px' }}>
          <Grid container spacing={2} alignItems="center">
            {paramMainCategoryId === '10' ?
              <Grid item xs={12} md={6}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setSelectedSupplierId(newValue?.value || null);
                  }}
                  options={suppliersList?.map((value) => ({
                    label: value.name,
                    value: value.id,
                  }))}
                  value={
                    selectedSupplierId
                      ? {
                        label:
                          suppliersList?.find((val) => val.id === selectedSupplierId)?.name ||
                          'Supplier Name',
                        value: selectedSupplierId,
                      }
                      : null
                  }
                  renderInput={(params) => <TextField label="Select Supplier Ledger" {...params} />}
                />
              </Grid> :
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={(event, newValue) => {
                  setSelectedSubCategoryId(newValue?.value || null);
                }}
                options={subCategory?.map((value) => ({
                  label: value.name,
                  value: value.id,
                }))}
                value={
                  selectedSubCategoryId
                    ? {
                      label:
                        subCategory?.find((val) => val.id === selectedSubCategoryId)?.name ||
                        'Ledger Name',
                      value: selectedSubCategoryId,
                    }
                    : null
                }
                renderInput={(params) => <TextField label="Select Ledger" {...params} />}
              />
            </Grid> }

            <Grid item xs={12} md={6}>
              <MobileDateRangePicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                onOpen={() => setOpenPicker(true)}
                value={dateRange}
                onChange={(newValue) => {}}
                onAccept={(newValue) => {
                  setDateRange(
                    newValue.map((date, index) =>
                      date
                        ? index === 1
                          ? moment(date).local().endOf('day').toDate()
                          : moment(date).local().startOf('day').toDate()
                        : null
                    )
                  );
                }}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <TextField
                    ref={inputRef}
                    {...inputProps}
                    InputProps={{
                      ...InputProps,
                    }}
                    fullWidth
                    label="Select Date Range"
                    value={formatDateRangeDisplay(dateRange)}
                    onClick={() => setOpenPicker(true)}
                    readOnly
                  />
                )}
              />
            </Grid>
          </Grid>
        </Card>
        {accessVerify('CASHFLOW_VIEW') ? (
          <DataGridTable
            name={'Assets List'}
            data={cashFlowList}
            column={columns}
            isLoading={false}
            enableRowSelection={false}
            enableRowActions={false}
            isRowClickable={false}
            renderRowActionItems={(value, closeMenu) => []}
          />
        ) : (
          <PermissionRequired />
        )}
      </Container>
    </Page>
  );
}
