import { PATH_DASHBOARD } from '../../../routes/paths';
import Label from '../../../components/Label';
import { BASE_URL } from '../../../config';
import Iconify from '../../../components/Iconify';

const navConfig = () =>
  [
    {
      subheader: 'Dashboard',
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.dashboard.root,
          icon: <Iconify icon={'material-symbols:dashboard'} width={20} height={20} />,
          children: [
            {
              title: 'General',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Purchasing',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Stock',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Kitchen',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Sales',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Marketing',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'HR',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Quality',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Procurement',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Administration',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Finance',
              path: PATH_DASHBOARD.dashboard.root,
              code: 'DASHBOARD_ANALYTICS_PAGE',
            },
            {
              title: 'Metabase',
              path: PATH_DASHBOARD.dashboard.meta.root,
              children: [
                {
                  title: 'Sales Report',
                  path: PATH_DASHBOARD.dashboard.meta.sales,
                },
                {
                  title: 'Employee Report',
                  path: PATH_DASHBOARD.dashboard.meta.employees,
                },
                {
                  title: 'Stock Availability Checker',
                  path: PATH_DASHBOARD.dashboard.meta.stockChecker,
                },
                {
                  title: 'Stock Dashboard',
                  path: PATH_DASHBOARD.dashboard.meta.stockDashboard,
                },
              ],
            },
          ],
        },
      ],
      production: true,
    },

    {
      subheader: 'Purchasing',
      items: [
        {
          title: 'Purchasing',
          path: PATH_DASHBOARD.purchasing.root,
          icon: <Iconify icon={'mdi:cart'} width={20} height={20} />,
          children: [
            {
              title: 'Food Categories',
              path: PATH_DASHBOARD.purchasing.foods.foodCategory.root,
              code: 'FOOD_CATEGORY_PAGE',
              children: [
                {
                  title: 'Ingredients',
                  path: PATH_DASHBOARD.purchasing.foods.foodCategory.ingredient,
                  code: 'FOOD_CATEGORY_PAGE',
                },
                {
                  title: 'Precooked',
                  path: PATH_DASHBOARD.purchasing.foods.foodCategory.precooked,
                  code: 'FOOD_CATEGORY_PAGE',
                },
                {
                  title: 'Menu',
                  path: PATH_DASHBOARD.purchasing.foods.foodCategory.menu,
                  code: 'FOOD_CATEGORY_PAGE',
                },
                {
                  title: 'Variation',
                  path: PATH_DASHBOARD.purchasing.foods.foodCategory.variation,
                  code: 'FOOD_CATEGORY_PAGE',
                },
              ],
            },
            {
              title: 'Food List',
              path: PATH_DASHBOARD.purchasing.foods.foodTypes.root,
              code: 'FOOD_PAGE',
              children: [
                {
                  title: 'Ingredients',
                  path: PATH_DASHBOARD.purchasing.foods.foodTypes.ingredient,
                  code: 'FOOD_PAGE',
                },
                {
                  title: 'Precooked',
                  path: PATH_DASHBOARD.purchasing.foods.foodTypes.precooked,
                  code: 'FOOD_PAGE',
                },
                {
                  title: 'Menu',
                  path: PATH_DASHBOARD.purchasing.foods.foodTypes.menu,
                  code: 'FOOD_PAGE',
                },
                {
                  title: 'Variation',
                  path: PATH_DASHBOARD.purchasing.foods.foodTypes.variation,
                  code: 'FOOD_PAGE',
                },
              ],
            },
            {
              title: 'Stock Suppliers',
              path: PATH_DASHBOARD.purchasing.stockSupplier,
              code: 'STOCK_SUPPLIER_PAGE',
            },
            {
              title: 'Call & Get Prices',
              path: PATH_DASHBOARD.purchasing.priceChecker,
              code: 'CALL_AND_GET_PRICES_PAGE',
            },
            {
              title: 'Add to Cart',
              path: PATH_DASHBOARD.purchasing.addToCart,
              code: 'ADD_TO_CART_PAGE',
            },
            {
              title: 'Purchase Notes',
              path: PATH_DASHBOARD.purchasing.purchaseNotes,
              code: 'PURCHASE_NOTE_PAGE',
            },
            {
              title: 'Goods Receiving',
              path: PATH_DASHBOARD.purchasing.receiving,
              code: 'GOODS_RECEIVING_PAGE',
            },
            {
              title: 'Finance Confirm',
              path: PATH_DASHBOARD.purchasing.financeConfirmation,
              code: 'GOODS_RECEIVING_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'stock',
      items: [
        {
          title: 'Stock',
          path: PATH_DASHBOARD.stocks.root,
          icon: <Iconify icon={'ic:twotone-inventory'} width={20} height={20} />,
          children: [
            {
              title: 'View Stock',
              path: PATH_DASHBOARD.stocks.viewAll.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'View Full Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.viewAll,
                  code: 'VIEW_STOCK_PAGE',
                },
                {
                  title: 'View Sold Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.viewPOS,
                  code: 'VIEW_SOLD_STOCK',
                },
                {
                  title: 'View Return Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.returnOnly,
                  code: 'VIEW_SOLD_STOCK',
                },
                {
                  title: 'View Used Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.usedOnly,
                  code: 'VIEW_SOLD_STOCK',
                },
                {
                  title: 'View Waste Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.wasteOnly,
                  code: 'VIEW_SOLD_STOCK',
                },
                {
                  title: 'View Missing Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.missing,
                  code: 'VIEW_SOLD_STOCK',
                },
                {
                  title: 'View Misplace Stock',
                  path: PATH_DASHBOARD.stocks.viewAll.misplaceStockList,
                  code: 'VIEW_SOLD_STOCK',
                },
              ],
            },
            {
              title: 'Stock Transfer',
              path: PATH_DASHBOARD.stocks.transfer.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'View Transfer',
                  path: PATH_DASHBOARD.stocks.transfer.view,
                  code: 'STOCK_TRANSFER_PAGE',
                },
                {
                  title: 'Sender',
                  path: PATH_DASHBOARD.stocks.transfer.sender,
                  code: 'STOCK_SENDER_PAGE',
                },
              ],
            },
            {
              title: 'Stock Return',
              path: PATH_DASHBOARD.stocks.return.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'View Returns',
                  path: PATH_DASHBOARD.stocks.return.view,
                  code: 'STOCK_TRANSFER_PAGE',
                },
                {
                  title: 'Return Creator',
                  path: PATH_DASHBOARD.stocks.return.creator,
                  code: 'STOCK_SENDER_PAGE',
                },
              ],
            },
            {
              title: 'Stock Orders',
              path: PATH_DASHBOARD.stocks.stockOrder.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'Stock Order List',
                  path: PATH_DASHBOARD.stocks.stockOrder.view,
                  code: 'STOCK_ORDER_LIST_PAGE',
                },
                {
                  title: 'Create Stock Order',
                  path: PATH_DASHBOARD.stocks.stockOrder.create,
                  code: 'CREATE_STOCK_ORDER_LIST_PAGE',
                },
              ],
            },
            {
              title: 'Stock Manager',
              path: PATH_DASHBOARD.stocks.stockManager.root,
              code: 'GENERAL_DATA',
              children: [
                {
                  title: 'Waste Manager',
                  path: PATH_DASHBOARD.stocks.stockManager.wasteManager,
                  code: 'STOCK_BREAKER_PAGE',
                },
                {
                  title: 'Used Stock Manager',
                  path: PATH_DASHBOARD.stocks.stockManager.usedStockManager,
                  code: 'STOCK_BREAKER_PAGE',
                },
                // {
                //   title: 'Return Manager',
                //   path: PATH_DASHBOARD.stocks.stockManager.returnManager,
                //   code: 'STOCK_BREAKER_PAGE',
                // },
                {
                  title: 'Missing Manager',
                  path: PATH_DASHBOARD.stocks.stockManager.missingManager,
                  code: 'STOCK_BREAKER_PAGE',
                },
              ],
            },
            {
              title: 'Stock Breaker',
              path: PATH_DASHBOARD.stocks.breaker,
              code: 'STOCK_BREAKER_PAGE',
            },
            {
              title: 'Quantity Changer',
              path: PATH_DASHBOARD.stocks.qtyChanger,
              code: 'STOCK_QTY_CHANGER',
            },
            {
              title: 'Availability Checker',
              path: PATH_DASHBOARD.stocks.availabilityChecker.checker,
              code: 'AVAILABILITY_CHECKER_PAGE',
            },
            {
              title: 'Availability Report',
              path: PATH_DASHBOARD.stocks.availabilityChecker.report,
              code: 'SUPER_ADMIN',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Kitchen',
      items: [
        {
          title: 'Kitchen',
          path: PATH_DASHBOARD.kitchen.root,
          icon: <Iconify icon={'fa6-solid:kitchen-set'} width={20} height={20} />,
          children: [
            {
              title: 'Set Cooking Status',
              path: PATH_DASHBOARD.kitchen.cookingStatus,
              code: 'COOKING_STATUS_PAGE',
            },
            {
              title: 'Cooking Process',
              path: PATH_DASHBOARD.kitchen.cookingProcess,
              code: 'COOKING_PROCESS_PAGE',
            },
            {
              title: 'Set Store Status',
              path: PATH_DASHBOARD.kitchen.store,
              code: 'STORE_STATUS_PAGE',
            },
            {
              title: 'Outlet Kitchen Display',
              path: PATH_DASHBOARD.kitchen.display,
              code: 'KITCHEN_DISPLAY_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'Sales',
      items: [
        {
          title: 'Sales',
          path: PATH_DASHBOARD.sales.root,
          icon: <Iconify icon={'ic:outline-point-of-sale'} width={20} height={20} />,
          children: [
            {
              title: 'Sales List',
              path: PATH_DASHBOARD.sales.saleList,
              code: 'SALES_PAGE',
            },
            {
              title: 'Customer List',
              path: PATH_DASHBOARD.sales.customerList,
              code: 'SALES_PAGE',
            },
          ],
        },
      ],
      production: true,
    },

    {
      subheader: 'Marketing',
      items: [
        {
          title: 'Marketing',
          path: PATH_DASHBOARD.marketing.root,
          icon: <Iconify icon={'nimbus:marketing'} width={20} height={20} />,
          children: [
            {
              title: 'Discounts Manager',
              path: PATH_DASHBOARD.marketing.discounts,
              code: 'COOKING_STATUS_PAGE',
            },
            {
              title: 'Vouchers manager',
              path: PATH_DASHBOARD.marketing.vouchers,
              code: 'COOKING_PROCESS_PAGE',
            },
            {
              title: 'Bulk SMS',
              path: PATH_DASHBOARD.marketing.bulksms,
              code: 'COOKING_PROCESS_PAGE',
            },
            {
              title: 'Expense',
              path: PATH_DASHBOARD.marketing.expenses.root,
              children: [
                {
                  title: 'View Expenses',
                  path: PATH_DASHBOARD.marketing.expenses.view,
                  code: 'USER_LIST_PAGE',
                },
                {
                  title: 'Expense Categories',
                  path: PATH_DASHBOARD.marketing.expenses.category,
                  code: 'USER_LIST_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
          ],
        },
      ],
      production: true,
    },
    {
      subheader: 'HR',
      items: [
        {
          title: 'HR',
          path: PATH_DASHBOARD.hr.root,
          icon: <Iconify icon={'bi:people-fill'} width={20} height={20} />,
          children: [
            {
              title: 'Employees',
              path: PATH_DASHBOARD.hr.employees.root,
              children: [
                {
                  title: 'Users List',
                  path: PATH_DASHBOARD.hr.employees.list,
                  code: 'USER_LIST_PAGE',
                },
                {
                  title: 'Former Users List',
                  path: PATH_DASHBOARD.hr.employees.formerList,
                  code: 'USER_LIST_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
            {
              title: 'Salary',
              path: PATH_DASHBOARD.hr.salary.root,
              children: [
                {
                  title: 'Time Log Uploader',
                  path: PATH_DASHBOARD.hr.salary.timeLog,
                  code: 'TIME_LOG_UPLOADER_PAGE',
                },
                {
                  title: 'Salary Generator',
                  path: PATH_DASHBOARD.hr.salary.generateSalary,
                  code: 'SALARY_GENERATOR_PAGE',
                },
              ],
              code: 'SALARY_PAGE',
            },

            {
              title: 'Rost Manager',
              path: PATH_DASHBOARD.hr.rost.manager,
              code: 'ROST_MANAGER_PAGE',
            },
            {
              title: 'Leave Manager',
              path: PATH_DASHBOARD.hr.leave.manager,
              code: 'LEAVE_MANAGER_PAGE',
            },
            {
              title: 'HR Reports',
              path: PATH_DASHBOARD.hr.report.root,
              children: [
                {
                  title: 'Salary Reports',
                  path: PATH_DASHBOARD.hr.report.root,
                  code: 'SALARY_REPORTS_PAGE',
                },
              ],
            },
            {
              title: 'Training',
              path: PATH_DASHBOARD.hr.training.root,
              children: [
                {
                  title: 'General',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Dashboard',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Purchasing',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Stock',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Kitchen',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Sales',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Marketing',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'HR',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Procurement',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'administration',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Finance',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
                {
                  title: 'Settings',
                  path: PATH_DASHBOARD.hr.training.root,
                  code: 'GENERAL_DATA',
                },
              ],
            },
            {
              title: 'Expense',
              path: PATH_DASHBOARD.hr.expenses.root,
              children: [
                {
                  title: 'View Expenses',
                  path: PATH_DASHBOARD.hr.expenses.view,
                  code: 'EXPENSE_HR_PAGE',
                },
                {
                  title: 'Expense Categories',
                  path: PATH_DASHBOARD.hr.expenses.category,
                  code: 'EXPENSE_HR_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
          ],
          code: 'GENERAL_DATA',
        },
      ],
      production: true,
    },
    {
      subheader: 'Quality',
      items: [
        {
          title: 'Quality',
          icon: <Iconify icon={'lets-icons:check-fill'} width={20} height={20} />,
          path: PATH_DASHBOARD.quality.root,
          children: [
            {
              title: 'Inspection',
              path: PATH_DASHBOARD.quality.inspection.root,
              children: [
                {
                  title: 'Inspection Type',
                  path: PATH_DASHBOARD.quality.inspection.type,
                  code: 'INSPECTION_TYPE_PAGE',
                },
                {
                  title: 'Inspection Template',
                  path: PATH_DASHBOARD.quality.inspection.templateList,
                  code: 'INSPECTION_TYPE_PAGE',
                },
                {
                  title: 'Inspection Examin',
                  path: PATH_DASHBOARD.quality.inspection.examin,
                  code: 'INSPECTION_EXAMIN_PAGE',
                },
              ],
              code: 'INSPECTION_TYPE_PAGE',
            },
            {
              title: 'Expense',
              path: PATH_DASHBOARD.quality.expenses.root,
              children: [
                {
                  title: 'View Expenses',
                  path: PATH_DASHBOARD.quality.expenses.view,
                  code: 'EXPENSE_HR_PAGE',
                },
                {
                  title: 'Expense Categories',
                  path: PATH_DASHBOARD.quality.expenses.category,
                  code: 'EXPENSE_HR_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
          ],
          code: 'GENERAL_DATA',
        },
      ],
      production: true,
    },
    {
      subheader: 'Procurement',
      items: [
        {
          title: 'Procurement',
          path: PATH_DASHBOARD.procurement.root,
          icon: <Iconify icon={'bxs:purchase-tag-alt'} width={20} height={20} />,
          children: [
            {
              title: 'Assets',
              path: PATH_DASHBOARD.procurement.assets.root,
              children: [
                {
                  title: 'Assets List',
                  path: PATH_DASHBOARD.procurement.assets.list,
                  code: 'ASSETS_LIST_PAGE',
                },
                {
                  title: 'Asset Categories',
                  path: PATH_DASHBOARD.procurement.assets.categories,
                  code: 'ASSET_CATEGORIES_PAGE',
                },
              ],
            },
            {
              title: 'Asset Suppliers',
              path: PATH_DASHBOARD.procurement.suppliers.root,
              children: [
                {
                  title: 'Assets Suppliers',
                  path: PATH_DASHBOARD.procurement.suppliers.assetSupplier,
                  code: 'ASSET_SERVICE_SUPPLIER_PAGE',
                },
                {
                  title: 'A. Service Suppliers',
                  path: PATH_DASHBOARD.procurement.suppliers.serviceSupplier,
                  code: 'ASSET_SERVICE_SUPPLIER_PAGE',
                },
              ],
            },
            {
              title: 'Expense',
              path: PATH_DASHBOARD.procurement.expenses.root,
              children: [
                {
                  title: 'View Expenses',
                  path: PATH_DASHBOARD.procurement.expenses.view,
                  code: 'EXPENSE_PROCUREMENT_PAGE',
                },
                {
                  title: 'Expense Categories',
                  path: PATH_DASHBOARD.procurement.expenses.category,
                  code: 'EXPENSE_PROCUREMENT_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
          ],
          code: 'ASSETS_PAGE',
        },
      ],
      production: true,
    },
    {
      subheader: 'Administration',
      items: [
        {
          title: 'Administration',
          path: PATH_DASHBOARD.administration.root,
          icon: <Iconify icon={'game-icons:expense'} width={20} height={20} />,
          children: [
            {
              title: 'Other Suppliers',
              path: PATH_DASHBOARD.administration.otherSupplier,
              code: 'ASSET_SERVICE_SUPPLIER_PAGE',
            },
            {
              title: 'Expense',
              path: PATH_DASHBOARD.administration.expenses.root,
              children: [
                {
                  title: 'View Expenses',
                  path: PATH_DASHBOARD.administration.expenses.view,
                  code: 'EXPENSE_PROCUREMENT_PAGE',
                },
                {
                  title: 'Expense Categories',
                  path: PATH_DASHBOARD.administration.expenses.category,
                  code: 'EXPENSE_PROCUREMENT_PAGE',
                },
              ],
              code: 'EMPLOYEES_PAGE',
            },
          ],
          code: 'INSPECTION_TYPE_PAGE',
        },
      ],
      production: true,
    },
    {
      subheader: 'Finance',
      items: [
        {
          title: 'Finance',
          path: PATH_DASHBOARD.finance.root,
          icon: <Iconify icon={'map:finance'} width={20} height={20} />,
          children: [
            {
              title: 'GRN Payments',
              code: 'CASHFLOW_TRANSFERS_PAGE',
              path: PATH_DASHBOARD.finance.grnPayments.root,
              children: [
                {
                  title: 'GRN to Be Paid',
                  path: PATH_DASHBOARD.finance.grnPayments.initiate,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Initiate & Confirm',
                  path: PATH_DASHBOARD.finance.grnPayments.confirm,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Mark Payment',
                  path: PATH_DASHBOARD.finance.grnPayments.paid,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Paid',
                  path: PATH_DASHBOARD.finance.grnPayments.finalized,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Deleted',
                  path: PATH_DASHBOARD.finance.grnPayments.deleted,
                  code: 'PAYMENT_TYPES_PAGE',
                },
              ],
            },
            {
              title: 'Sale Deposit Verif.',
              path: PATH_DASHBOARD.finance.saleDepositVerification,
              code: 'CASHFLOW_TRANSFERS_PAGE',
            },
            {
              title: 'Ctrl Account',
              code: 'CASHFLOW_TRANSFERS_PAGE',
              path: PATH_DASHBOARD.finance.controllerAccount.root,
              children: [
                {
                  title: 'Revenue',
                  path: PATH_DASHBOARD.finance.controllerAccount.revenue,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Cost of Sales',
                  path: PATH_DASHBOARD.finance.controllerAccount.costOfSale,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Admin Expenses',
                  path: PATH_DASHBOARD.finance.controllerAccount.adminExpenses,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'S.D.M Expenses',
                  path: PATH_DASHBOARD.finance.controllerAccount.sellingDistributionAndMarkertingExpense,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Finance Expenses',
                  path: PATH_DASHBOARD.finance.controllerAccount.financeExpense,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Non Current Assets',
                  path: PATH_DASHBOARD.finance.controllerAccount.nonCurrentAssets,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Current Assets',
                  path: PATH_DASHBOARD.finance.controllerAccount.currentAssets,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Cashbook',
                  path: PATH_DASHBOARD.finance.controllerAccount.cashbook,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Equity & Liabilities',
                  path: PATH_DASHBOARD.finance.controllerAccount.equityAndLiabilities,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Trade Payable',
                  path: PATH_DASHBOARD.finance.controllerAccount.tradePayable,
                  code: 'PAYMENT_TYPES_PAGE',
                }
              ],
            },
            {
              title: 'Finance Settings',
              code: 'CASHFLOW_TRANSFERS_PAGE',
              path: PATH_DASHBOARD.finance.setting.root,
              children: [
                {
                  title: 'Transac. Medium',
                  path: PATH_DASHBOARD.finance.setting.paymentTypes,
                  code: 'PAYMENT_TYPES_PAGE',
                },
                {
                  title: 'Document Type',
                  path: PATH_DASHBOARD.finance.setting.cashFlowDocumentType,
                  code: 'CASHFLOW_DOCUMENT_TYPE_PAGE',
                },
                // {
                //   title: 'CF Categories',
                //   path: PATH_DASHBOARD.finance.setting.cashflowCategories,
                //   code: 'CASHFLOW_CATEGORIES_PAGE',
                // },
              ],
            },
            // {
            //   title: 'Expense',
            //   path: PATH_DASHBOARD.finance.expenses.root,
            //   children: [
            //     {
            //       title: 'View Expenses',
            //       path: PATH_DASHBOARD.finance.expenses.view,
            //       code: 'EXPENSE_FINANCE_PAGE',
            //     },
            //     {
            //       title: 'Expense Categories',
            //       path: PATH_DASHBOARD.finance.expenses.category,
            //       code: 'EXPENSE_FINANCE_PAGE',
            //     },
            //   ],
            //   code: 'EMPLOYEES_PAGE',
            // },
          ],
          code: 'FINANCE_PAGE',
          production: true,
        },
      ],
      production: true,
    },
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Settings',
          path: PATH_DASHBOARD.setting.root,
          icon: <Iconify icon={'material-symbols-light:settings'} width={20} height={20} />,
          children: [
            {
              title: 'General Settings',
              path: PATH_DASHBOARD.setting.general.root,
              children: [
                {
                  title: 'Business Locations',
                  path: PATH_DASHBOARD.setting.general.locations,
                  code: 'BUSINESS_LOCATIONS_PAGE',
                },
              ],
              code: 'GENERAL_SETTINGS_PAGE',
            },
            {
              title: 'Account Settings',
              path: PATH_DASHBOARD.setting.account.root,
              children: [
                {
                  title: 'My Account',
                  path: PATH_DASHBOARD.setting.account.ownAccount,
                  code: 'MY_ACCOUNT_PAGE',
                },
              ],
              code: 'ACCOUNT_SETTINGS_PAGE',
            },
          ],
          code: 'SETTINGS_PAGE',
        },
      ],
      production: true,
    },
    {
      subheader: 'general',
      items: [
        {
          title: 'app',
          path: PATH_DASHBOARD.general.app,
        },
        {
          title: 'e-commerce',
          path: PATH_DASHBOARD.general.ecommerce,
        },
        {
          title: 'analytics',
          path: PATH_DASHBOARD.general.analytics,
        },
        {
          title: 'banking',
          path: PATH_DASHBOARD.general.banking,
        },
        {
          title: 'booking',
          path: PATH_DASHBOARD.general.booking,
        },
      ],
      production: false,
    },
    {
      subheader: 'management',
      production: false,
      items: [
        {
          title: 'user',
          path: PATH_DASHBOARD.user.root,
          children: [
            { title: 'profile', path: PATH_DASHBOARD.user.profile },
            { title: 'cards', path: PATH_DASHBOARD.user.cards },
            { title: 'list', path: PATH_DASHBOARD.user.list },
            { title: 'create', path: PATH_DASHBOARD.user.new },
            { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
            { title: 'account', path: PATH_DASHBOARD.user.account },
          ],
          production: false,
        },
        {
          title: 'e-commerce',
          path: PATH_DASHBOARD.eCommerce.root,
          children: [
            { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
            { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
            { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
            { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
            { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
            { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          ],
          production: false,
        },
        {
          title: 'invoice',
          path: PATH_DASHBOARD.invoice.root,
          children: [
            { title: 'list', path: PATH_DASHBOARD.invoice.list },
            { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
            { title: 'create', path: PATH_DASHBOARD.invoice.new },
            { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
          ],
          production: false,
        },
        {
          title: 'blog',
          path: PATH_DASHBOARD.blog.root,
          children: [
            { title: 'posts', path: PATH_DASHBOARD.blog.posts },
            { title: 'post', path: PATH_DASHBOARD.blog.demoView },
            { title: 'create', path: PATH_DASHBOARD.blog.new },
          ],
          production: false,
        },
      ],
    },
    {
      subheader: 'app',
      items: [
        {
          title: 'mail',
          path: PATH_DASHBOARD.mail.root,
          info: (
            <Label variant='outlined' color='error'>
              +32
            </Label>
          ),
        },
        { title: 'chat', path: PATH_DASHBOARD.chat.root },
        {
          title: 'calendar',
          path: PATH_DASHBOARD.calendar,
        },
        { title: 'kanban', path: PATH_DASHBOARD.kanban },
      ],
      production: false,
    },
  ]
    .map((item) => {
      if (BASE_URL.includes('localhosts')) {
        return item;
      } else {
        if (item.production) {
          return item;
          // const dataObj = {
          //   ...item,
          //   items: item.items
          //     .map((innerItem) => {
          //       return {
          //         ...innerItem,
          //         children: innerItem.children
          //           ?.filter((children) => permissionsList.includes(children?.code) || permissionsList.includes('SUPER_ADMIN'))
          //           ?.map((value) => {
          //             if (value?.children?.length > 0) {
          //               const childrenValuesList = value?.children?.filter((children) => permissionsList.includes(children?.code) || permissionsList.includes('SUPER_ADMIN'));
          //               return childrenValuesList?.length > 0
          //                 ? {
          //                     ...value,
          //                     children: value?.children?.filter((children) => permissionsList.includes(children?.code) || permissionsList.includes('SUPER_ADMIN')),
          //                   }
          //                 : null;
          //             } else {
          //               return value;
          //             }
          //           })
          //           ?.filter((value) => (value?.children ? value?.children?.length > 0 : value)),
          //       };
          //     })
          //     .filter((value) => value?.children?.length > 0),
          // };
          // return dataObj?.items?.length > 0 ? dataObj : null;
        } else {
          return null;
        }
      }
    })
    .filter((value) => value);

export default navConfig;
