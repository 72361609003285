import moment from 'moment-timezone';

export const jsonParseArray = (value) => {
  try {
    return safeJSONParse(value) || [];
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const capitalize = (str) => {
  if (!str || typeof str !== 'string') return '';
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const truncateStringValue = (str, maxLength) => {
  if (!str || typeof str !== 'string') return '';
  return str?.length > maxLength ? str?.slice(0, maxLength - 3) + '...' : str;
};

export const handleNumber = (value) => {
  if (!value) return 0;
  return isNaN(Number(value)) ? 0 : Number(value);
};

export const safeJSONParse = (value) => {
  if (!value) return null;
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const utcMoment = (dateTime) => {
  if (!dateTime) return moment().utc();
  return moment(dateTime).utc();
};

export const utcMomentDifferenceInMinutes = (dateTime) => {
  if (!dateTime) return moment().utc();
  const timeString = moment().format('YYYY-MM-DDTHH:mm:ss.000Z')?.replace('+05:30', 'Z');
  const toTime =  utcMoment(dateTime);
  const fromTime = utcMoment(timeString);
  return fromTime.diff(toTime, 'minutes');
};

export const padNumber = (num, length) => num.toString().padStart(length, '0');

export const getTwoDigitsNumber = (number) => {
  if (number === null || number === undefined) return '';
  return number.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};

export const accessVerify = (code) => {
  const permissionList = safeJSONParse(localStorage.getItem('permissionsList')) || [];
  return permissionList.includes(code) || (permissionList.includes('PAYMENT_ACTIVE') && permissionList.includes('SUPER_ADMIN'));
};

export const removeUnwantedStockBarCodeList = (codeList) => {
  return codeList?.filter((barcode) => {
    const code = barcode?.code || '';
    return !(code.includes("waste") || code.includes("pos") || code.includes("Used") || code.includes("Return") || code.includes("Missing"));
  }) || []
}