import React, { useEffect, useState } from 'react';
import { Box, Card, Container, IconButton, Switch, Typography, TextField, InputAdornment, } from '@mui/material';
import { MobileDateRangePicker } from '@mui/lab';
import { dispatch, useSelector } from '../../../../redux/store';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import useSettings from '../../../../hooks/useSettings';
import Page from '../../../../components/Page';
import HeaderBreadcrumbs from '../../../../components/HeaderBreadcrumbs';
import DataGridTable from '../../../../components/table/DataGridTable';
import { approveMovedStock, getStocks, restoreWastedStock } from '../../../../redux/slices/stockManagementRedux/stockRedux';
import { accessVerify, capitalize, safeJSONParse, utcMoment } from '../../../../utils/common';
import { useParams } from 'react-router-dom';
import { fCurrency } from '../../../../utils/formatNumber';
import RestoreIcon from '@mui/icons-material/Restore';
import { CalendarToday, CheckCircleOutline, Clear, RemoveRedEye } from '@material-ui/icons';
import moment from 'moment';

const StockList = () => {
  const { showPOSOnly } = useParams();
  const { themeStretch } = useSettings();
  const [purchaseNotesList, setPurchaseNotesList] = useState([]);
  const [tempPurchaseNotesList, setTempPurchaseNotesList] = useState([]);
  const [rowSelection, setRowSelection] = useState(false);
  const [includeEmptyStock, setIncludeEmptyStock] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pendingForApprovalCount, setPendingForApprovalCount] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [openPicker, setOpenPicker] = useState(false);

  const { data: stocks, addData } = useSelector((state) => state?.stock);
  const { selectedLocation } = useSelector((state) => state?.location);

  useEffect(() => {
    dispatch(getStocks(selectedLocation?.id, -1, -1, -1, showPOSOnly, includeEmptyStock));
  }, [selectedLocation, showPOSOnly, includeEmptyStock, addData]);

  useEffect(() => {
    setTempPurchaseNotesList(
      stocks?.map((value) => {
        return {
          actionButton: safeJSONParse(value?.foodInfo)?.foodName,
          food: safeJSONParse(value?.foodInfo)?.foodName,
          foodType: capitalize(safeJSONParse(value?.foodInfo)?.foodType),
          location: safeJSONParse(value?.locationInfo)?.locationName,
          ...value,
          date: utcMoment(value?.date).format('DD MMMM YYYY'),
          unit: safeJSONParse(value?.unitInfo)?.unitName,
          supplier: safeJSONParse(value?.supplierInfo)?.supplierName,
          supplierCode: safeJSONParse(value?.supplierInfo)?.supplierCode,
          expireDate: utcMoment(value?.expireDate).format('DD MMMM YYYY'),
        };
      })
    );
  }, [stocks]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const filteredStocks = tempPurchaseNotesList.filter((stock) => {
        const stockDate = utcMoment(stock.date, 'DD MMMM YYYY');
        return stockDate.isBetween(dateRange[0], dateRange[1], null, '[]');
      });
      setPurchaseNotesList(filteredStocks);
    } else {
      setPurchaseNotesList(tempPurchaseNotesList);
    }
  }, [dateRange, tempPurchaseNotesList]);

  useEffect(() => {
    if(purchaseNotesList){
      setPendingForApprovalCount(purchaseNotesList?.filter(value=> !value?.approvedBy)?.length);
      if (purchaseNotesList?.length > 0) {
        setTotalPrice(purchaseNotesList?.map((value) => value?.costPerUnit * value?.currentQty)?.reduce((a, c) => a + c) || 0);
      } else {
        setTotalPrice(0);
      }
    }
  }, [purchaseNotesList]);

  const columns = purchaseNotesList?.[0]
    ? Object.keys(purchaseNotesList?.[0])
      .map((value) => {
        if (value === 'foodInfo' || value === 'locationInfo' || value === 'supplierInfo' || value === 'unitInfo' || value === 'foodSupplierInfo' || value === 'parentId') {
          return null;
        } else if (value === 'actionButton') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return <><IconButton color='primary' onClick={() => {
                const url = `/dashboard/stock/single-stock-item/${row.original.stockCode}`;
                window.open(url, '_blank', 'noopener,noreferrer');
              }}><RemoveRedEye /></IconButton>
                {!['full','sold'].includes(showPOSOnly) && row.original?.approvedBy === null && accessVerify("STOCK_QTY_APPROVE") && <IconButton color="success" onClick={() => { dispatch(approveMovedStock(row?.original)); }}><CheckCircleOutline /></IconButton>}
                {row.original?.parentId && accessVerify("STOCK_QTY_RESTORE") && <IconButton color="primary" onClick={() => { dispatch(restoreWastedStock(row?.original)); }}><RestoreIcon /></IconButton>}
              </>;
            },
          };
        } else if (value === 'costPerUnit') {
          return {
            accessorKey: value,
            header: capitalize(value),
            Cell: ({ cell, row }) => {
              return fCurrency(row.original[value]);
            },
          };
        } else if (value === 'orderId') {
          return {
            accessorKey: value,
            header: "Purchase Note ID",
            Cell: ({ cell, row }) => {
              return `GRN-${row.original[value]}`;
            },
          };
        } else {
          return {
            accessorKey: value,
            header: capitalize(value),
          };
        }
      })
      .filter(Boolean)
    : [];

  const formatDateRangeDisplay = (range) => {
    if (!range[0] || !range[1]) return '';
    return `${utcMoment(range[0]).format('DD MMM YYYY')} - ${utcMoment(range[1]).format('DD MMM YYYY')}`;
  };


  const clearDateRange = () => {
    setDateRange([null, null]);
  };

  return (
    <Page title='View Stock'>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={`View ${capitalize(showPOSOnly)} Stock`}
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Stock', href: PATH_DASHBOARD.stocks.root }, { name: `View ${capitalize(showPOSOnly)}` }]}
          action={[]}
        />
        <Card sx={{m: 1}}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MobileDateRangePicker
              open={openPicker}
              onClose={() => setOpenPicker(false)}
              onOpen={() => setOpenPicker(true)}
              value={dateRange}
              onChange={(newValue) => {}}
              onAccept={(newValue) => {
                setDateRange(
                  newValue.map((date, index) =>
                    date
                      ? index === 1
                        ? moment(date).local().endOf('day').toDate()
                        : moment(date).local().startOf('day').toDate()
                      : null
                  )
                );
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <TextField
                  ref={inputRef}
                  {...inputProps}
                  InputProps={{
                    ...InputProps,
                    startAdornment: (
                      <InputAdornment position="start">
                        <CalendarToday sx={{ color: 'text.secondary' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={clearDateRange}
                          sx={{ p: 0 }}
                        >
                          <Clear sx={{ color: 'text.secondary' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label="Select Date Range"
                  value={formatDateRangeDisplay(dateRange)}
                  onClick={() => setOpenPicker(true)}
                  readOnly
                  sx={{ width: 250 }}
                />
              )}
            />
            <Switch
              checked={includeEmptyStock}
              onChange={(e) => {
                setIncludeEmptyStock(e.target.checked);
              }}
              size="small"
            />
            <Typography variant="overline" sx={{ color: 'text.secondary', ml: 1 }}>
              Include Empty Stock
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <Typography variant="h6" sx={{ color: 'red', fontWeight: '500' }}>
              {fCurrency(totalPrice)}
            </Typography>
            {!['full', 'sold'].includes(showPOSOnly) && (
              <Typography variant="body2" sx={{ color: 'orange', fontWeight: '400' }}>
                Pending Approval: {pendingForApprovalCount}
              </Typography>
            )}
          </Box>
        </Box>
        </Card>

        {accessVerify('VIEW_STOCK_VIEW') && (
          <DataGridTable
            name={'View Stock'}
            data={purchaseNotesList}
            column={columns}
            isLoading={false}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            keyField={'stockId'}
            isRowClickable={false}
            onRowClick={(row) => {
              const url = `/dashboard/stock/single-stock-item/${row?.stockCode}`;
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
          />
        )}
      </Container>
    </Page>
  );
};

export default StockList;
