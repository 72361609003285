import PropTypes from 'prop-types';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { fCurrency } from '../../../../utils/formatNumber';
import styles from './InvoiceStyle';

InvoicePDF.propTypes = { invoice: PropTypes.object.isRequired };

export default function InvoicePDF({ invoice, paymentVoucher={} }) {
  const { items, taxes, status, dueDate, discount, invoiceTo, createDate, totalPrice, invoiceFrom, invoiceNumber, subTotalPrice, paidAmount = 0, topic = '', stockCodes = [], linkedDocuments } = invoice;
  const { voucherId, voucherStatus, voucherCreatedDate, voucherScheduledDate, voucherPaymentMethod, voucherReferenceNumber, voucherInitiatedByUser,voucherApprovedByUser, voucherMarkedBy } = paymentVoucher;

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image source='/logo/invoice_logo.png' style={{ height: 80 }} />
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h1}>{topic}</Text>
            <Text style={styles.h3}>{status}</Text>
            <Text> {invoiceNumber} </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Invoice from</Text>
            <Text style={styles.body1}>{invoiceFrom.name}</Text>
            <Text style={styles.body1}>{invoiceFrom.address}</Text>
            <Text style={styles.body1}>{invoiceFrom.phone}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Invoice to</Text>
            <Text style={styles.body1}>{invoiceTo.name}</Text>
            <Text style={styles.body1}>{invoiceTo.address}</Text>
            <Text style={styles.body1}>{invoiceTo.phone}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Date create</Text>
            <Text style={styles.body1}>{createDate}</Text>
          </View>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb8]}>Due date</Text>
            <Text style={styles.body1}>{dueDate}</Text>
          </View>
        </View>

        <Text style={[styles.overline, styles.mb8]}>Invoice Details</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Qty</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Unit price</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {items.map((item, index) => {
              const barcodes = stockCodes?.filter(value => (value?.foodId === item?.foodId) && !(value?.code.includes('Waste') && value?.code.includes('Used') && value?.code.includes('Return') && value?.code.includes('Missing')))?.map(value => value?.code);
              return (
                <View style={styles.tableRow} key={item.id}>
                  <View style={styles.tableCell_1}>
                    <Text>{index + 1}</Text>
                  </View>

                  <View style={styles.tableCell_2}>
                    <Text style={styles.subtitle2}>{item.title}</Text>
                    <Text>{item.description}</Text>
                    <Text>Standard Measurement: {item.storeUnitQty}</Text>
                    {barcodes?.length > 0 && <Text>Entered Barcodes:</Text>}
                    {barcodes?.length > 0 && <Text>{barcodes?.toString()?.replaceAll(',', ' , ')}</Text>}
                  </View>

                  <View style={styles.tableCell_3}>
                    <Text>{item.quantity}</Text>
                  </View>

                  <View style={styles.tableCell_3}>
                    <Text>{fCurrency(item.price)}</Text>
                  </View>

                  <View style={[styles.tableCell_3, styles.alignRight]}>
                    <Text>{fCurrency(item.price * item.quantity)}</Text>
                  </View>
                </View>
              );
            })}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(subTotalPrice)}</Text>
              </View>
            </View>

            {discount > 0 && (
              <View style={[styles.tableRow, styles.noBorder]}>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2} />
                <View style={styles.tableCell_3} />
                <View style={styles.tableCell_3}>
                  <Text>Discount</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(-discount)}</Text>
                </View>
              </View>
            )}

            {taxes > 0 && (
              <View style={[styles.tableRow, styles.noBorder]}>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2} />
                <View style={styles.tableCell_3} />
                <View style={styles.tableCell_3}>
                  <Text>Taxes</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(taxes)}</Text>
                </View>
              </View>
            )}

            {paidAmount > 0 && (
              <View style={[styles.tableRow, styles.noBorder]}>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2} />
                <View style={styles.tableCell_3} />
                <View style={styles.tableCell_3}>
                  <Text>Paid Amount</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(paidAmount)}</Text>
                </View>
              </View>
            )}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text style={styles.h4}>Total</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>{fCurrency(totalPrice)}</Text>
              </View>
            </View>
          </View>
        </View>
        <br />
        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col8}>
            <Text style={styles.subtitle2}>NOTES</Text>
            <Text>We appreciate your business. Should you need us to add VAT or extra notes let us know!</Text>
          </View>
          <View style={[styles.col4, styles.alignRight]}>
            <Text style={styles.subtitle2}>Have a Question?</Text>
            <Text>finance@bubblemania.lk</Text>
          </View>
        </View>
      </Page>
        <Page size="A4" style={styles.page}>
        {linkedDocuments?.map((document, index) => {
          return (
            <>
              <Image
                key={index}
                style={{ width: '100%' }}
                src={document}
              />
            </>
          );
        })}

        <View
          style={{
            position: 'absolute',
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: 'center',
            fontSize: 10,
            borderTop: '1px solid #000',
            paddingTop: 5,
          }}
        >
        </View>
      </Page>

      {voucherStatus && <Page size="A4" style={styles.page}>
        <View style={[styles.gridContainer, styles.mb40]}>
          <Image source="/logo/invoice_logo.png" style={{ height: 80 }} />
          <View style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
            <Text style={styles.h1}>Payment Voucher</Text>
            <Text style={styles.h3}>{voucherStatus}</Text>
            <Text> {voucherId} </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb4]}>from</Text>
            <Text style={styles.body1}>{invoiceFrom.name}</Text>
            <Text style={styles.body1}>{invoiceFrom.address}</Text>
            <Text style={styles.body1}>{invoiceFrom.phone}</Text>
          </View>

          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb4]}>to</Text>
            <Text style={styles.body1}>{invoiceTo.name}</Text>
            <Text style={styles.body1}>{invoiceTo.address}</Text>
            <Text style={styles.body1}>{invoiceTo.phone}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb4]}>Date create</Text>
            <Text style={styles.body1}>{voucherCreatedDate}</Text>
          </View>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb4]}>Due date</Text>
            <Text style={styles.body1}>{voucherScheduledDate}</Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.mb40]}>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb4]}>Voucher Payment Method</Text>
            <Text style={styles.body1}>{voucherPaymentMethod}</Text>
          </View>
          <View style={styles.col6}>
            <Text style={[styles.overline, styles.mb4]}>Payment Reference</Text>
            <Text style={styles.body1}>{voucherReferenceNumber}</Text>
          </View>
        </View>

        <Text style={[styles.overline, styles.mb4]}>Invoice Details</Text>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>#</Text>
              </View>

              <View style={styles.tableCell_2}>
                <Text style={styles.subtitle2}>Description</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Qty</Text>
              </View>

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Unit price</Text>
              </View>

              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.subtitle2}>Total</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            {items.map((item, index) => {
              const barcodes = stockCodes?.filter(value => (value?.foodId === item?.foodId) && !(value?.code.includes('Waste') && value?.code.includes('Used') && value?.code.includes('Return') && value?.code.includes('Missing')))?.map(value => value?.code);
              return (
                <View style={styles.tableRow} key={item.id}>
                  <View style={styles.tableCell_1}>
                    <Text>{index + 1}</Text>
                  </View>

                  <View style={styles.tableCell_2}>
                    <Text style={styles.subtitle2}>{item.title}</Text>
                    <Text>{item.description}</Text>
                    <Text>Standard Measurement: {item.storeUnitQty}</Text>
                    {barcodes?.length > 0 && <Text>Entered Barcodes:</Text>}
                    {barcodes?.length > 0 && <Text>{barcodes?.toString()?.replaceAll(',', ' , ')}</Text>}
                  </View>

                  <View style={styles.tableCell_3}>
                    <Text>{item.quantity}</Text>
                  </View>

                  <View style={styles.tableCell_3}>
                    <Text>{fCurrency(item.price)}</Text>
                  </View>

                  <View style={[styles.tableCell_3, styles.alignRight]}>
                    <Text>{fCurrency(item.price * item.quantity)}</Text>
                  </View>
                </View>
              );
            })}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text>Subtotal</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text>{fCurrency(subTotalPrice)}</Text>
              </View>
            </View>

            {discount > 0 && (
              <View style={[styles.tableRow, styles.noBorder]}>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2} />
                <View style={styles.tableCell_3} />
                <View style={styles.tableCell_3}>
                  <Text>Discount</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(-discount)}</Text>
                </View>
              </View>
            )}

            {taxes > 0 && (
              <View style={[styles.tableRow, styles.noBorder]}>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2} />
                <View style={styles.tableCell_3} />
                <View style={styles.tableCell_3}>
                  <Text>Taxes</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(taxes)}</Text>
                </View>
              </View>
            )}

            {paidAmount > 0 && (
              <View style={[styles.tableRow, styles.noBorder]}>
                <View style={styles.tableCell_1} />
                <View style={styles.tableCell_2} />
                <View style={styles.tableCell_3} />
                <View style={styles.tableCell_3}>
                  <Text>Paid Amount</Text>
                </View>
                <View style={[styles.tableCell_3, styles.alignRight]}>
                  <Text>{fCurrency(paidAmount)}</Text>
                </View>
              </View>
            )}

            <View style={[styles.tableRow, styles.noBorder]}>
              <View style={styles.tableCell_1} />
              <View style={styles.tableCell_2} />
              <View style={styles.tableCell_3} />
              <View style={styles.tableCell_3}>
                <Text style={styles.h4}>Total</Text>
              </View>
              <View style={[styles.tableCell_3, styles.alignRight]}>
                <Text style={styles.h4}>{fCurrency(totalPrice)}</Text>
              </View>
            </View>
          </View>

          <View style={styles.tableBody}>
            <View style={styles.voucherTableRow}>
              <View style={styles.tableCell_1}>
                <Text>1</Text>
              </View>
              <View style={styles.tableCell_2}>
                <Text>Initiated By</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text>{voucherInitiatedByUser}</Text>
              </View>
            </View>

            <View style={styles.voucherTableRow}>
              <View style={styles.tableCell_1}>
                <Text>2</Text>
              </View>
              <View style={styles.tableCell_2}>
                <Text>Approved By</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text>{voucherApprovedByUser}</Text>
              </View>
            </View>

            <View style={styles.voucherTableRow}>
              <View style={styles.tableCell_1}>
                <Text>3</Text>
              </View>
              <View style={styles.tableCell_2}>
                <Text>Payment Marked By</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text>{voucherMarkedBy}</Text>
              </View>
            </View>
          </View>
        </View>
        {/*<br />*/}
        {/*<View style={[styles.gridContainer, styles.footer]}>*/}
        {/*  <View style={styles.col8}>*/}
        {/*    <Text style={styles.subtitle2}>NOTES</Text>*/}
        {/*    <Text>We appreciate your business. Should you need us to add VAT or extra notes let us know!</Text>*/}
        {/*  </View>*/}
        {/*  <View style={[styles.col4, styles.alignRight]}>*/}
        {/*    <Text style={styles.subtitle2}>Have a Question?</Text>*/}
        {/*    <Text>finance@bubblemania.lk</Text>*/}
        {/*  </View>*/}
        {/*</View>*/}
      </Page>}
    </Document>
  );
}
